import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import generateFeesTablePdf from '../utils/reports/generateFeesTablePdf';
import generateFeesTableCsv from '../utils/reports/generateFeesTableCsv';
import generateLevyReportedPdf from '../utils/reports/generateLevyReportedPdf';
import generateSubmissionsTablePdf from '../utils/reports/generateSubmissionsTablePdf';
import generateSubmissionsTableCsv from '../utils/reports/generateSubmissionsTableCsv';
import { getSummaryReportData } from '../actions/summaryReports';
import { reportFormUpdate, reportFormError, reportFormReset } from '../actions/reportForm';
import { openReportModal, closeReportModal } from '../actions/modals';
import { setSelectedAssociationID } from '../actions/selectedAssociation';
import filterReports from '../selectors/reports';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class SummaryReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.updatePaymentStatus = this.updatePaymentStatus.bind(this);
        this.generateSummaryReport = this.generateSummaryReport.bind(this);

        this.state = {
            selectedReport: {}
        };
    }

    generateSummaryReport() {
        this.props.dispatch(getSummaryReportData(this.props.loggedRole.municipality_id, this.props.loggedRole.association_id, this.props.reportForm));
    }

    componentDidMount() {
        verifyLogin(this.props.history);
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleUpdateModal(isOpen, selectedReport) {
        if (isOpen) {
            this.setState(() => ({
                selectedReport
            }));
            this.props.dispatch(reportFormReset());
            this.props.dispatch(reportFormUpdate({ prop: 'role', value: selectedReport.role }));
            this.props.dispatch(reportFormUpdate({ prop: 'payment_complete', value: selectedReport.payment_complete }));
            this.props.dispatch(openReportModal());
        }
        else {
            this.props.dispatch(closeReportModal());
        }
    }

    renderUpdatePaymentButton(selectedReport) {
        console.log(selectedReport.payment_complete);
        if (selectedReport.payment_complete) {
            return (
                <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={() => { this.props.dispatch(updatePaymentStatus(selectedReport.id, { payment_complete: false })) }}>
                    Set to 'Unpaid'
                </Button>
            );
        } else {
            return (
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => { this.props.dispatch(updatePaymentStatus(selectedReport.id, { payment_complete: true })) }}>
                    Set to 'Paid'
                </Button>
            );
        }
    }


    updatePaymentStatus() {

        // add the committee report
        this.props.dispatch(updatePaymentStatus(this.state.selectedReport.id, this.props.reportForm));
    }

    renderReportStatus(isComplete) {
        return (isComplete) ? 'Paid' : 'Not Paid';
    }

    renderReportsList() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            {/* <h4 className={classes.cardTitleWhite}>***Municipality Name***{this.props.association.name}</h4> */}
                            <p className={classes.cardCategoryWhite}>
                                Summary Reports
                            </p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className="summary-report-menu">
                                <GridItem xs={12} sm={12} md={3}>
                                    <InputLabel htmlFor="start-month">Start Month</InputLabel>
                                    <Select
                                        value={this.props.reportForm.start_month}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'start_month', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'start_month',
                                            id: 'start-month',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Month
                                        </MenuItem>
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                <InputLabel htmlFor="start-year">Start Year</InputLabel>
                                    <Select
                                        value={this.props.reportForm.start_year}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'start_year', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'start_year',
                                            id: 'start-year',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Year
                                        </MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2025}>2025</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <InputLabel htmlFor="end-month">End Month</InputLabel>
                                    <Select
                                        value={this.props.reportForm.end_month}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'end_month', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'end_month',
                                            id: 'end-month',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Month
                                        </MenuItem>
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                    <InputLabel htmlFor="end-year">End Year</InputLabel>
                                    <Select
                                        value={this.props.reportForm.end_year}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'end_year', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'end_year',
                                            id: 'end-year',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Year
                                        </MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2025}>2025</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <InputLabel className="summary-report-menu-input" htmlFor="report-type">Report Type</InputLabel>
                                    <Select
                                        value={this.props.reportForm.report_type}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'report_type', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'report_type',
                                            id: 'report-type',
                                        }}
                                    >
                                        <MenuItem value={'levy_reported'}>Levy Reported</MenuItem>
                                        {(this.props.loggedRole.role == 'municipality' || this.props.loggedRole.role == 'municipality_admin') && (<MenuItem value={'past_due_fees'}>Fees Past Due</MenuItem>)}
                                        {(this.props.loggedRole.role == 'municipality' || this.props.loggedRole.role == 'municipality_admin') && (<MenuItem value={'past_due_submissions'}>Submissions Past Due</MenuItem>)}
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <Button
                                        type="button"
                                        size="large"
                                        fullWidth={true}
                                        className="summary-report-menu-input"
                                        variant="contained"
                                        onClick={this.generateSummaryReport}
                                    >
                                        Generate Report
                                    </Button>
                                </GridItem>
                            </GridContainer>

                            {(this.props.showReport && this.props.summaryReportType == 'past_due_fees') && (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        className="download-pdf-button" 
                                        onClick={() => { generateFeesTablePdf(this.props.reportData, this.props.reportForm) }}
                                    >
                                        Download PDF
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        className="download-pdf-button" 
                                        onClick={() => { generateFeesTableCsv(this.props.reportData, this.props.reportForm) }}
                                    >
                                        Download CSV
                                    </Button>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Table
                                        tableHead={["Company", "Owner", "Email", "0 - 30 Days", "31 - 60 Days", "61 - 90 Days", "90+ Days", "Total"]}
                                        tableData={this.props.reportData.map((report) => [
                                            report.company, 
                                            report.owner,
                                            report.email,
                                            report['0-30'],
                                            report['31-60'],
                                            report['61-90'],
                                            report['90+'],
                                            report.total
                                        ])}
                                    />
                                </GridItem>
                            </GridContainer>
                            )}

                            {(this.props.showReport && this.props.summaryReportType == 'past_due_submissions') && (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            className="download-pdf-button" 
                                            onClick={() => { generateSubmissionsTablePdf(this.props.reportData, this.props.reportForm) }}
                                        >
                                            Download PDF
                                        </Button>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            className="download-pdf-button" 
                                            onClick={() => { generateSubmissionsTableCsv(this.props.reportData, this.props.reportForm) }}
                                        >
                                            Download CSV
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Table
                                            tableHead={["Company", "Address", "Owner", "Email", "Total Submissions Due", "Required Submissions"]}
                                            tableData={this.props.reportData.map((report) => [
                                                report.name,
                                                report.address, 
                                                report.owner_name,
                                                report.owner_email,
                                                report.total_reports,
                                                report.report_dates
                                            ])}
                                        />
                                    </GridItem>
                                </GridContainer>
                            )}

                            {(this.props.showReport && this.props.summaryReportType == 'levy_reported') && (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            className="download-pdf-button" 
                                            onClick={() => { generateLevyReportedPdf(this.props.reportData, this.props.reportForm) }}
                                        >
                                            Download PDF
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={8}>
                                        <div className="dashboard-panel report-summary-panel">
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h2>Levies Reported</h2>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6}>
                                                    <p className="report-levy-reported-label">Total Room Revenue for Period:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6}> 
                                                    <p className="report-levy-reported-amount">${this.props.reportData.total_room_revenue.toFixed(2)}</p>
                                                </GridItem>
        
                                                <GridItem xs={6} sm={6} md={6}>
                                                    <p className="report-levy-reported-label">(Deduct): Adjustments for Bad Debts and Refund due to Errors</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6}> 
                                                    <p className="report-levy-reported-amount report-levy-reported-deduction">-${this.props.reportData.adjustment_refunds.toFixed(2)}</p>
                                                </GridItem>
        
                                                <GridItem xs={6} sm={6} md={6}>
                                                    <p className="report-levy-reported-label">Add: Adjustments to Revenue:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6}> 
                                                    <p className="report-levy-reported-amount">${this.props.reportData.adjustment_revenue.toFixed(2)}</p>
                                                </GridItem>
        
                                                <GridItem xs={6} sm={6} md={6}>
                                                    <p className="report-levy-reported-label">Adjusted Room Revenue subject to Levy in Reporting Period:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6}> 
                                                    <p className="report-levy-reported-amount">${this.props.reportData.revenue_subject_to_levy.toFixed(2)}</p>
                                                </GridItem>

                                                <GridItem xs={6} sm={6} md={6}>
                                                    <p className="report-levy-reported-label label-total">Total Marketing Levy Due:</p>
                                                </GridItem>
                                                <GridItem xs={6} sm={6} md={6}> 
                                                    <p className="report-levy-reported-amount label-total">${this.props.reportData.total_due.toFixed(2)}</p>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <div className="dashboard-panel report-summary-panel">
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h2>Accommodations Reporting</h2>
                                                    <p className="report_total_accommodations_reporting">{this.props.reportData.accommodations_reporting.toFixed(2)}%</p>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderReportsList()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        loggedUser: state.auth.user,
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.summaryReports.loading,
        reportData: state.summaryReports.reportData,
        showReport: state.summaryReports.showSummaryReport,
        summaryReportType: state.summaryReports.summaryReportType,
        reportForm: state.reportForm,
        reportModalOpen: state.modals.reportModal,
        association: state.selectedAssociation.association
    };
};

export default connect(mapStateToProps)(withStyles(styles)(SummaryReportsPage));
