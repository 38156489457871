import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import UpdateVendor from "./modal/UpdateVendor";
import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { getVendors, addVendor, removeVendor } from '../actions/vendors';
import { vendorFormUpdate, vendorFormError, vendorFormReset, vendorFormLoad } from '../actions/vendorForm';
import { openVendorsCreate, closeVendorsCreate, openVendorsUpdate, closeVendorsUpdate } from '../actions/modals';
import { setSelectedAssociationID } from '../actions/selectedAssociation';
import filterVendors from '../selectors/vendors';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class VendorsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitCreateVendor = this.submitCreateVendor.bind(this);
        this.removeVendor = this.removeVendor.bind(this);

        this.state = {
            selectedVendor: {}
        };
    }

    removeVendor({ id }) {
        if (confirm("Are you sure you want to remove this committee vendor? This cannot be undone")) {
            this.props.dispatch(removeVendor(id, this.props.match.params.id));
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getVendors());
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(vendorFormReset());
            this.props.dispatch(openVendorsCreate());
        }
        else {
            this.props.dispatch(closeVendorsCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedVendor) {
        if (isOpen) {
            this.setState(() => ({
                selectedVendor
            }));
            this.props.dispatch(vendorFormReset());
            this.props.dispatch(vendorFormLoad(selectedVendor));
            this.props.dispatch(openVendorsUpdate());
        }
        else {
            this.props.dispatch(closeVendorsUpdate());
        }
    }

    submitCreateVendor() {

        // @note: temporarily disabling until we figure out what we want here.
        // perform some error checking
        // if (this.props.vendorForm.name.trim() == "") {

        //     // required fields aren't present
        //     this.props.dispatch(vendorFormError("Please enter a vendor name."));
        // }
        // else if (this.props.vendorForm.licence_number.trim() == "") {

        //     // required fields aren't present
        //     this.props.dispatch(vendorFormError("Please enter a licence number."));
        // }
        // else 
        if (this.props.vendorForm.association_id == 0) {

            // required fields aren't present
            this.props.dispatch(vendorFormError("Please enter an association."));
        }
        else if (this.props.vendorForm.municipality_id == 0) {

            // required fields aren't present
            this.props.dispatch(vendorFormError("Please enter a municipality."));
        }
        else {

            // add the vendor
            this.props.dispatch(addVendor(this.props.vendorForm));
        }
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="association-vendor-add"
                aria-describedby="association-vendor-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Add a Vendor
                    </Typography>
                    <Typography className="modal-description">
                        Please add all required information.
                    </Typography>

                    { this.props.vendorForm.error && (
                        <Typography className="modal-error">
                            { this.props.vendorForm.error }
                        </Typography>
                    )}

                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.name}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.address}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'address', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="City"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.city}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'city', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Postal Code"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.postal_code}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'postal_code', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="standard-name"
                        label="Licence #"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.licence_number}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'licence_number', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Corporate Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.corporate_name}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'corporate_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Mailing Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.mailing_address}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'mailing_address', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.owner_name}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'owner_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Email"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.owner_email}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'owner_email', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.phone}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'phone', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Password (leave blank if you do not want to create a user"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.password}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Fax"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.owner_fax}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'owner_fax', value: e.target.value })); }}
                        margin="normal"
                    />

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-vendor-role">Vendor Type</InputLabel>
                        <Select
                            value={this.props.vendorForm.vendor_type}
                            onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'vendor_type', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'vendor_type',
                                id: 'new-vendor-type',
                            }}
                        >
                            <MenuItem value="">
                                Select a Vendor Type
                            </MenuItem>
                            <MenuItem value={'accommodation'}>Accommodation</MenuItem>
                            <MenuItem value={'hotel'}>Hotel</MenuItem>
                            <MenuItem value={'motel'}>Motel</MenuItem>
                        </Select>
                    </FormControl>

                    {(this.props.loggedRole.role == "admin") && (
                        <FormControl className="form-control">
                            <InputLabel htmlFor="new-vendor-association">Association</InputLabel>
                            <Select
                                value={this.props.vendorForm.association_id}
                                onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'association_id', value: e.target.value })); }}
                                fullWidth={true}
                                className="modal-select"
                                inputProps={{
                                    name: 'association_id',
                                    id: 'new-vendor-association',
                                }}
                            >
                                <MenuItem value="">
                                    Select an Association
                                </MenuItem>
                                {this.props.associationsList.map((association) => (
                                    <MenuItem key={association.id} value={association.id}>{association.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {(this.props.loggedRole.role == "admin") && (
                        <FormControl className="form-control">
                            <InputLabel htmlFor="new-vendor-municipality">Municipality</InputLabel>
                            <Select
                                value={this.props.vendorForm.municipality_id}
                                onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'municipality_id', value: e.target.value })); }}
                                fullWidth={true}
                                className="modal-select"
                                inputProps={{
                                    name: 'municipality_id',
                                    id: 'new-vendor-municipality',
                                }}
                            >
                                <MenuItem value="">
                                    Select a Municipality
                                </MenuItem>
                                {this.props.municipalitiesList.map((municipality) => (
                                    <MenuItem key={municipality.id} value={municipality.id}>{municipality.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateVendor}>
                        Add Vendor
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return <UpdateVendor selectedVendor={this.state.selectedVendor} />;
    }

    printRole(theRole) {
        switch(theRole) {

            case "owner":
                return "Association Owner";

            case "admin":
                return "Administrator";

            case "manager_schedule":
                return "Schedule Manager";

            case "manager_all":
                return "Schedule/Team Manager";

            case "manager_team":
                return "Team Manager";
            
            case "coach":
                return "Coach";

            default: 
                return theRole;
        }
    }

    printStatus(theStatus) {
        if (theStatus == "confirmed") {
            return "Confirmed";
        }
        else if (theStatus == "pending") {
            return "Pending";
        }
        else {
            return theStatus;
        }
    }

    renderVendorsList() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>{this.props.association.name}</h4>
                            <p className={classes.cardCategoryWhite}>
                            Vendors
                            </p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="text"
                                        value={this.props.vendorForm.search}
                                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'search', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                            <Table
                                tableHead={["ID", "Name", "Licence Number", "Vendor Type", "Owner Name", "Phone", "Owner Email"]}
                                tableData={filterVendors(this.props.vendors, {search: this.props.vendorForm.search}).map((vendor) => [
                                    vendor.id,
                                    vendor.name, 
                                    vendor.licence_number, 
                                    vendor.vendor_type,
                                    vendor.owner_name,
                                    vendor.phone,
                                    vendor.owner_email,
                                    (
                                        <div>
                                            {(vendor.role != "owner") && <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, vendor)} />}
                                            {(vendor.role != "owner") && <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeVendor(vendor)} />}
                                        </div>
                                    )
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderVendorsList()}

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.vendors.loading,
        vendors: state.vendors.vendors,
        vendorForm: state.vendorForm,
        createModalOpen: state.modals.vendorsCreate,
        updateModalOpen: state.modals.vendorsUpdate,
        association: state.selectedAssociation.association,
        associationsList: state.vendors.associations_list,
        municipalitiesList: state.vendors.municipalities_list,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(VendorsPage));
