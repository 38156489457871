const vendorFormDefaultState = {
    name: '',
    address: '',
    city: '',
    postal_code: '',
    licence_number: '',
    corporate_name: '',
    mailing_address: '',
    owner_name: '',
    owner_email: '',
    phone: '',
    password: '',
    owner_fax: '',
    municipality_id: '',
    association_id: '',
    vendor_type: 'accommodation',
    date_started: '2024-01-01',
    message: '',
    error: '',
    search: '',
};

export default (state = vendorFormDefaultState, action) => {
    
    switch (action.type) {

        case 'VENDOR_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'VENDOR_FORM_LOAD':
            return {
                ...state,
                ...action.vendor
            };

        case 'VENDOR_FORM_CONFIGURE':
            return {
                ...state,
                closed_for_season: action.data.closed_for_season,
                trip_purpose: {
                    vacationing: action.data.vacationing || 0,
                    business: action.data.business || 0,
                    convention: action.data.convention || 0,
                    motorcoach: action.data.motorcoach || 0,
                    other: action.data.other || 0
                }
            };

        case 'VENDOR_FORM_PURPOSE_UPDATE':
            return {
                ...state,
                trip_purpose: {
                    ...state.trip_purpose,
                    [action.field.prop]: action.field.value
                }
            };

        case 'VENDOR_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'VENDOR_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'VENDOR_FORM_RESET':
            return vendorFormDefaultState;

        default:
            return state;
    }
};