import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { closeLeftSidebar } from '../../actions/sidebar';
import { openProfileUpdate } from '../../actions/modals';
import { getDashboardInfo } from '../../actions/dashboard';

class LeftSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.closeSidebar = this.closeSidebar.bind(this);
    }



    closeSidebar() {
        this.props.dispatch(closeLeftSidebar());
    }

    render() {
        return (
            <Drawer open={this.props.isSidebarOpen} onClose={this.closeSidebar}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.closeSidebar}
                    onKeyDown={this.closeSidebar}
                    className="sidebar_left"
                >
                    <div className="list">

                        <List>
                            <ListItem button>
                                <ListItemText primary="Admin Menu" />
                            </ListItem>

                            {this.props.vendors && this.props.vendors.length <= 0 && (
                                <ListItem button onClick={() => { this.props.history.push('/dash'); }}>
                                    <ListItemIcon>
                                        <Icon className="fa fa-home" />
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard" />
                                </ListItem>
                            )}

                            {this.props.vendors && this.props.vendors.length > 0 && this.props.vendors.map((vendor) => {
                                return (
                                    <ListItem button onClick={() => { this.props.dispatch(getDashboardInfo(vendor.id));this.props.history.push('/dash/'+vendor.id); }}>
                                        <ListItemIcon key={vendor.id}>
                                            <Icon className="fa fa-home" />
                                        </ListItemIcon>
                                        <ListItemText primary={vendor.name} />
                                    </ListItem>
                                );
                            })}

                             {this.props.loggedRole.role == "admin" && (<ListItem button onClick={() => { this.props.history.push('/vendors'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-luggage-cart" />
                                </ListItemIcon>
                                <ListItemText primary="Vendors" />
                            </ListItem>)}

                            {this.props.loggedRole.role == "admin" && (<ListItem button onClick={() => { this.props.history.push('/associations'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-luggage-cart" />
                                </ListItemIcon>
                                <ListItemText primary="Associations" />
                            </ListItem>)}

                            {this.props.loggedRole.role == "admin" && (<ListItem button onClick={() => { this.props.history.push('/users'); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-luggage-cart" />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>)}

                            {this.props.loggedRole.role == "admin" && (<ListItem button onClick={() => { alert("Coming Soon!"); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-chart-bar" />
                                </ListItemIcon>
                                <ListItemText primary="Analytics" />
                            </ListItem>)}

                            <ListItem button onClick={() => { this.props.dispatch(openProfileUpdate()); }}>
                                <ListItemIcon>
                                    <Icon className="fa fa-user" />
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        isSidebarOpen: state.sidebar.left,
        association_id: state.selectedAssociation.id,
        vendor_id: state.occupancyForm.vendor_id,
        vendors: state.vendors.vendorsList
    };
};

export default withRouter(connect(mapStateToProps)(LeftSidebar));