import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { vendorFormReset, vendorFormError, vendorFormSuccess } from '../actions/vendorForm';
import { closeVendorsCreate } from './modals';
import { setDashboardVendor } from './dashboard';
import generateVendorReportPdf from '../utils/reports/generateVendorReportPdf';
import generateVendorReportCsv from '../utils/reports/generateVendorReportCsv';

export const setVendors = (vendors) => ({
    type: 'SET_VENDORS',
    vendors
});

export const setVendorsList = (vendorsList) => ({
    type: 'SET_VENDORS_LIST',
    vendorsList
});

export const setMunicipalitiesList = (municipalities_list) => ({
    type: 'SET_MUNICIPALITIES_LIST',
    municipalities_list
});

export const setAssociationsList = (associations_list) => ({
    type: 'SET_ASSOCIATIONS_LIST',
    associations_list
});

export const setVendorsLoading = () => ({
    type: 'SET_VENDORS_LOADING'
});

export const getVendors = (id) => {

    return (dispatch) => {

        dispatch(setVendorsLoading());
        return axios.get(API_URL+'/api/vendors')
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setVendors(res.data.vendors));
                    dispatch(setAssociationsList(res.data.associations));
                    dispatch(setMunicipalitiesList(res.data.municipalities));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get vendors!");
                    alert("Could not get vendors!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the vendor to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addVendor = (vendorInfo) => {
    
    return (dispatch) => {

        dispatch(setVendorsLoading());
        return axios.post(API_URL+'/api/vendors', vendorInfo)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setVendors(res.data.vendors));
                    dispatch(vendorFormReset());
                    dispatch(closeVendorsCreate());
                }
                else {
                    dispatch(vendorFormError(res.data.message));
                }
            })
            .catch(function(err) {
                // token is likely invalid; remove it and prompt the vendor to login again
                dispatch(vendorFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateVendor = (vid, vendorInfo) => {
    
    return (dispatch) => {

        dispatch(setVendorsLoading());
        return axios.post(API_URL+'/api/vendors/'+vid, vendorInfo)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setVendors(res.data.vendors));
                    dispatch(setDashboardVendor(res.data.vendor));
                    dispatch(vendorFormSuccess('Vendor Updated!'));
                }
                else {
                    dispatch(vendorFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your vendor!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the vendor to login again
                dispatch(vendorFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeVendor = (id) => {

    return (dispatch) => {

        dispatch(setVendorsLoading());
        return axios.post(API_URL+'/api/vendors/'+id+'/delete', {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setVendors(res.data.vendors));
                }
                else {
                    // dispatch(vendorFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your vendor!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the vendor to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}

export const generateVendorReport = (vendor, useCsv = false) => {

    return (dispatch) => {

        dispatch(setVendorsLoading());
        return axios.post(API_URL+'/api/vendors/'+vendor.id+'/report', {
            report_type: 'vendor'
        })
            .then(function(res) {
                if (res.data.status == 'success') {
                    if (useCsv) {
                        generateVendorReportCsv(vendor, res.data.reportData);
                    } else {
                        generateVendorReportPdf(vendor, res.data.reportData);
                    }
                }
                else {
                    // dispatch(vendorFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not get vendor data!");
                }
            })
            .catch(function(err) {

                console.log(err);
                // token is likely invalid; remove it and prompt the vendor to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });

    };
}