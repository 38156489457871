const sidebarDefaultState = {
    left: false,
    right: false
};

export default (state = sidebarDefaultState, action) => {
    switch (action.type) {

        case 'OPEN_LEFT_SIDEBAR':
            return {
                ...state,
                left: true
            };

        case 'CLOSE_LEFT_SIDEBAR':
            return {
                ...state,
                left: false
            };

        case 'OPEN_RIGHT_SIDEBAR':
            return {
                ...state,
                right: true
            };

        case 'CLOSE_RIGHT_SIDEBAR':
            return {
                ...state,
                right: false
            };

        default:
            return state;
    }
};