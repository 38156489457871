import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { associationFormReset, associationFormError } from '../actions/associationForm';
import { closeAssociationsCreate } from './modals';

export const setAssociations = (associations) => ({
    type: 'SET_ASSOCIATIONS',
    associations
});

export const setAssociationsLoading = () => ({
    type: 'SET_ASSOCIATIONS_LOADING'
});

export const getAssociations = (id) => {

    return (dispatch) => {

        dispatch(setAssociationsLoading());
        return axios.get(API_URL+'/api/associations')
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAssociations(res.data.associations));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get associations!");
                    alert("Could not get associations!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the association to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addAssociation = (associationInfo) => {
    
    return (dispatch) => {

        dispatch(setAssociationsLoading());
        return axios.post(API_URL+'/api/associations', associationInfo)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAssociations(res.data.associations));
                    dispatch(associationFormReset());
                    dispatch(closeAssociationsCreate());
                }
                else {
                    dispatch(associationFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the association to login again
                dispatch(associationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateAssociation = (aid, associationInfo) => {
    
    return (dispatch) => {

        dispatch(setAssociationsLoading());
        return axios.post(API_URL+'/api/associations/'+aid, associationInfo)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAssociations(res.data.associations));
                    dispatch(associationFormSuccess('Association Updated!'));
                }
                else {
                    dispatch(associationFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your association!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the association to login again
                dispatch(associationFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

// export const removeAssociation = (mid, id) => {

//     return (dispatch) => {

//         dispatch(setAssociationsLoading());
//         return axios.delete(API_URL+'/api/associations/'+id+'/'+mid, {})
//             .then(function(res) {
//                 if (res.data.status == 'success') {
//                     dispatch(setAssociations(res.data.associations));
//                 }
//                 else {
//                     // dispatch(associationFormError(res.data.message));
//                     console.log(res.data);
//                     console.log("Could not remove your association!");
//                 }
//             })
//             .catch(function(err) {

//                 // token is likely invalid; remove it and prompt the association to login again
//                 if (err.response.status === 401) {
//                     removeAuthToken(dispatch);
//                 }
                
//             });
//     };
// }