// the default list of reports, which is empty
const SummaryReportsDefaultState = {
    reportData: [],
    summaryReportType: 'past_due_fees',
    showSummaryReport: false,
    loading: true
};

export default (state = SummaryReportsDefaultState, action) => {
    switch (action.type) {

        case 'SET_SUMMARY_REPORTS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_REPORT_DATA':
            return {
                ...state,
                reportData: action.reportData,
                summaryReportType: action.reportType,
                showSummaryReport: true,
                loading: false
            };

        default:
            return state;
    }
};