import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';

export const associationFormUpdate = ({ prop, value }) => {

    return {
        type: 'ASSOCIATION_FORM_UPDATE',
        field: { prop, value }
    };
};

export const associationFormLoad = (association) => {

    return {
        type: 'ASSOCIATION_FORM_LOAD',
        association
    };
};

export const associationFormError = (message) => {

    return {
        type: 'ASSOCIATION_FORM_ERROR',
        message
    };
};

export const associationFormSuccess = (message) => {

    return {
        type: 'ASSOCIATION_FORM_SUCCESS',
        message
    };
};

export const associationFormReset = () => {
    
    return {
        type: 'ASSOCIATION_FORM_RESET',
    };
};