import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Bar, Line } from 'react-chartjs-2';
import verifyLogin from '../utils/verifyLogin';
import { getCurrentTime } from '../utils/dateUtil';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import { getDashboardInfo } from '../actions/dashboard';
import { occupancyFormUpdate } from '../actions/occupancyForm';
import { submitOccupancy } from '../actions/occupancy';
import AlertsIcon from '@material-ui/icons/NotificationsActive';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ReportsIcon from '@material-ui/icons/Hotel';
import NewReportIcon from '@material-ui/icons/Computer';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import { reportFormUpdate, submitLeveeReport, reportFormReset } from '../actions/reportForm';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import UpdateVendor from "./modal/UpdateVendor";
import SummaryReport from "./modal/SummaryReport";
import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import { openReportModal, closeReportModal, openVendorsUpdate, closeVendorsUpdate, openSummaryReportModal, closeSummaryReportModal } from '../actions/modals';
import { vendorFormReset, vendorFormLoad } from '../actions/vendorForm';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);

        this.submitTodayBookings = this.submitTodayBookings.bind(this);
        this.updateVendor = this.updateVendor.bind(this);
        this.toggleReportModal = this.toggleReportModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);

        this.state = {
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);

        // update here
        let vid = (this.props.match.params.vid) ? this.props.match.params.vid : 0;
        this.props.dispatch(getDashboardInfo(vid));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleReportModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(reportFormReset());
            this.props.dispatch(openReportModal());
        }
        else {
            this.props.dispatch(closeReportModal());
        }
    }

    toggleSummaryReportModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openSummaryReportModal());
        }
        else {
            this.props.dispatch(closeSummaryReportModal());
        }
    }

    toggleUpdateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(vendorFormReset());
            this.props.dispatch(vendorFormLoad(this.props.vendor));
            this.props.dispatch(openVendorsUpdate());
        }
        else {
            this.props.dispatch(closeVendorsUpdate());
        }
    }

    updateVendor(e) {
        if (this.props.occupancy[e.target.value]) {
            this.props.dispatch(occupancyFormUpdate({ prop: 'total', value: this.props.occupancy[e.target.value].total_rooms }));
        }
        else {
            this.props.dispatch(occupancyFormUpdate({ prop: 'total', value: 0 }));
        }
        this.props.dispatch(occupancyFormUpdate({ prop: 'vendor_id', value: e.target.value }));
    }

    submitTodayBookings() {

        // submit the time to book
        this.props.dispatch(submitOccupancy(this.props.sitename, getCurrentTime(true), this.props.vendor_id, this.props.total, this.props.total_occupants));
    }

    reportFormUpdate({ prop, value }) {

        this.props.dispatch(reportFormUpdate({ prop, value }));

        let adjustedRoomRevenue = 
            ((prop === 'totalRoomRevenue') ? parseFloat(value) : parseFloat(this.props.reportForm.totalRoomRevenue)) - 
            ((prop === 'refundErrors') ? parseFloat(value) : parseFloat(this.props.reportForm.refundErrors)) + 
            ((prop === 'revenueAdjustments') ? parseFloat(value) : parseFloat(this.props.reportForm.revenueAdjustments));
        let totalLevyDue = adjustedRoomRevenue * 0.03;
        this.props.dispatch(reportFormUpdate({ prop: 'adjustedRoomRevenue', value: adjustedRoomRevenue }));
        this.props.dispatch(reportFormUpdate({ prop: 'totalLevyDue', value: totalLevyDue }));
    }

    displayVendorAddress() {
        if (this.props.vendor && this.props.vendor.address) {
            return this.props.vendor.address + ', ' + this.props.vendor.city + ', ' + this.props.vendor.postal_code;
        }

        return 'No address on file';
    }

    displayFormMonth(value) {
        switch(value) {
            case '01':
                return 'January';

            case '02':
                return 'February';

            case '03':
                return 'March';
    
            case '04':
                return 'April';

            case '05':
                return 'May';

            case '06':
                return 'June';

            case '07':
                return 'July';

            case '08':
                return 'August';
    
            case '09':
                return 'September';

            case '10':
                return 'October';

            case '11':
                return 'November';

            case '12':
                return 'December';

            default:
                return 'Invalid Month';
        }
    }

    renderReportModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="association-user-add"
                aria-describedby="association-user-add"
                open={this.props.reportModalOpen}
                onClose={() => this.toggleReportModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Monthly Levy Report
                    </Typography>
                    
                    { !this.props.reportForm.showComplete && (
                        <div>
                            { this.props.reportForm.error && (
                                <Typography className="modal-error">
                                    { this.props.reportForm.error }
                                </Typography>
                            )}

                            <Typography className="modal-description">
                                Reporting Period - Select Month & Year
                            </Typography>

                            <GridContainer justify="center" alignItems="center">
                                <GridItem xs={12} sm={6} md={6}>
                                    <InputLabel htmlFor="levee-report-month">Month</InputLabel>
                                    <Select
                                        value={this.props.reportForm.month}
                                        onChange={(e) => { this.reportFormUpdate({ prop: 'month', value: e.target.value }); }}
                                        fullWidth={true}
                                        className="modal-select"
                                        inputProps={{
                                            name: 'month',
                                            id: 'levee-report-month',
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="">
                                            Select a Month
                                        </MenuItem>
                                        <MenuItem value={'01'}>January</MenuItem>
                                        <MenuItem value={'02'}>February</MenuItem>
                                        <MenuItem value={'03'}>March</MenuItem>
                                        <MenuItem value={'04'}>April</MenuItem>
                                        <MenuItem value={'05'}>May</MenuItem>
                                        <MenuItem value={'06'}>June</MenuItem>
                                        <MenuItem value={'07'}>July</MenuItem>
                                        <MenuItem value={'08'}>August</MenuItem>
                                        <MenuItem value={'09'}>September</MenuItem>
                                        <MenuItem value={'10'}>October</MenuItem>
                                        <MenuItem value={'11'}>November</MenuItem>
                                        <MenuItem value={'12'}>December</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <InputLabel htmlFor="levee-report-year">Year</InputLabel>
                                    <Select
                                        value={this.props.reportForm.year}
                                        onChange={(e) => { this.reportFormUpdate({ prop: 'year', value: e.target.value }); }}
                                        fullWidth={true}
                                        className="modal-select"
                                        inputProps={{
                                            name: 'year',
                                            id: 'levee-report-year',
                                        }}
                                        variant="outlined"
                                    >
                                        <MenuItem value="">
                                            Select a Year
                                        </MenuItem>
                                        <MenuItem value={'2024'}>2024</MenuItem>
                                        <MenuItem value={'2025'}>2025</MenuItem>
                                    </Select>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6}>
                                    <Typography className="modal-description">
                                        Total Room Nights Sold
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.reportForm.totalRooms}
                                        onChange={(e) => { this.reportFormUpdate({ prop: 'totalRooms', value: e.target.value }); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Typography className="modal-description">
                                        Total Room Revenue for Period
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.reportForm.totalRoomRevenue}
                                        onChange={(e) => { this.reportFormUpdate({ prop: 'totalRoomRevenue', value: e.target.value }); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Typography className="modal-description">
                                        (Deduct): Adjustments for Bad Debts and Refund due to Errors
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        className="modal-input modal-input-deduction"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.reportForm.refundErrors}
                                        onChange={(e) => { this.reportFormUpdate({ prop: 'refundErrors', value: e.target.value }); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Typography className="modal-description">
                                        Add: Adjustments to Revenue
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.reportForm.revenueAdjustments}
                                        onChange={(e) => { this.reportFormUpdate({ prop: 'revenueAdjustments', value: e.target.value }); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Typography className="modal-description">
                                        Adjusted Room Revenue subject to Levy in reporting period
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.reportForm.adjustedRoomRevenue.toFixed(2)}
                                        margin="normal"
                                        variant="outlined"
                                        disabled={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Typography className="modal-description">
                                        Total Marketing Levy Due (3%):
                                    </Typography>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="number"
                                        value={this.props.reportForm.totalLevyDue.toFixed(2)}
                                        margin="normal"
                                        variant="outlined"
                                        disabled={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="true" checked={this.props.reportForm.agreed} onChange={(e) => this.props.dispatch(reportFormUpdate({ prop: 'agreed', value: !this.props.reportForm.agreed }))} color="primary" />}
                                        label="I attest, on behalf of the organization, that the information provided above is accurate and complete."
                                    />
                                </GridItem>
                            </GridContainer>

                            <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.props.dispatch(submitLeveeReport(this.props.vendor_id, this.props.reportForm))}>
                                Submit Report
                            </Button>
                        </div>
                    )}
                    { this.props.reportForm.showComplete && (
                        <div>
                            <p className="featured-icon-window"><SuccessIcon className="featured-icon" /></p>
                            
                            <p className="success-message">Market Levy Report Successfully Submitted for {this.displayFormMonth(this.props.reportForm.month)}, {this.props.reportForm.year}</p>


                            <div className="window-amount-due">
                                <p className="amount-title">Amount Due</p>
                                <p className="amount-due">${this.props.reportForm.totalLevyDue.toFixed(2)}</p>
                            </div>

                            <div className="window-cheque-location">

                                {![6, 8, 9].includes(this.props.vendor.municipality_id) && (
                                    <p className="cheque-instructions">Please make cheques payable to your Municipal office.</p>
                                )}
                                
                                {[6, 8, 9].includes(this.props.vendor.municipality_id) && (
                                    <div>
                                        <p className="cheque-instructions">Please make cheques payable to your Municipal office, or please use <a href="https://ipn.paymentus.com/rotp/mcol">this portal link</a> to complete your payment:</p>
                                        <p className="cheque-instructions"><a href="https://ipn.paymentus.com/rotp/mcol">https://ipn.paymentus.com/rotp/mcol</a></p>
                                    </div>
                                )}
                            </div>  
                        </div>
                    )}
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return <UpdateVendor selectedVendor={this.props.vendor} />;
    }

    renderSummaryReportModal() {
        return <SummaryReport />;
    }
    
    renderVendorDashboard() {
        setBackgroundPhoto('');
        
        const data = {
            // labels: ['Nov 2022', 'Dec 2022', 'Jan 2023', 'Feb 2023', 'Mar 2023'],
            labels: this.props.chartData.labels,
            datasets: [
              {
                label: 'Occupancy Rate',
                // backgroundColor: '#38b6ff',
                borderColor: '#38b6ff',
                borderWidth: 1,
                // pointHoverBackgroundColor: '#134866',
                pointHoverBorderColor: '#134866',
                lineTension: 0,
                data: this.props.chartData.data
                // data: [55, 42, 31, 30, 62]
              }
            ]
        };
        
        return (
            <GridContainer>
                <GridItem xs={12} sm={9} md={9}>
                    {/* <div className="daily-report-window"> */}
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <h2><AlertsIcon className="dashboard-alert-icon" /> Alerts</h2>
                                    <ul>
                                        
                                        {this.props.alerts.map((alert) => (
                                            <li className="alert-item" key={alert}><span className="alert-text">{alert} - <a onClick={() => { this.toggleReportModal(true) }}>Submit Now</a></span></li>
                                        ))}
                                    </ul>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <p className="featured-icon-window"><ReportsIcon className="featured-icon" /></p>
                                    <div className="dashboard-button-window">
                                        <Button
                                            type="button"
                                            size="large"
                                            fullWidth={true}
                                            className="modal-button-bottom dashboard-panel-button"
                                            variant="contained"
                                            onClick={() => { this.props.history.push('/reports'); }}
                                        >
                                            View Past Reports
                                        </Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <p className="featured-icon-window"><NewReportIcon className="featured-icon" /></p>
                                    <div className="dashboard-button-window">
                                        <Button
                                            type="button"
                                            size="large"
                                            fullWidth={true}
                                            className="modal-button-bottom dashboard-panel-button"
                                            variant="contained"
                                            onClick={() => { this.toggleReportModal(true) }}
                                        >
                                            Submit New Report
                                        </Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className="dashboard-panel">
                                    <h2>Monthly Accommodations Reported - Past 6 Months</h2>
                                    <Line
                                        data={data}
                                        height={100}
                                        options={{
                                            responsive: true
                                        }}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <div className="dashboard-contact-panel">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h2>{this.props.vendor.name}</h2>
                                <p className="address">{this.displayVendorAddress()}</p>
                                <p className="registration">Reg. #: {(this.props.vendor.licence_number) ? this.props.vendor.licence_number : "Unavailable"}</p>
                            </GridItem>
                        </GridContainer>
                        
                        <div className="corporate-address">
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Corporate Name:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p>{(this.props.vendor.corporate_name) ? this.props.vendor.corporate_name : "Unavailable"}</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Mailing Address:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p>{(this.props.vendor.mailing_address) ? this.props.vendor.mailing_address : "Unavailable"}</p>
                                </GridItem>
                            </GridContainer>
                        </div>

                        <div className="divider"></div>

                        <div className="corporate-address">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h3>Contact Information</h3>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Contact Name:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p>{(this.props.vendor.owner_name) ? this.props.vendor.owner_name : "Unavailable"}</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Email:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p>{(this.props.vendor.owner_email) ? this.props.vendor.owner_email : "Unavailable"}</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Telephone:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p>{(this.props.vendor.phone) ? this.props.vendor.phone : "Unavailable"}</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Fax:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p>{(this.props.vendor.fax) ? this.props.vendor.fax : "Unavailable"}</p>
                                </GridItem>
                            </GridContainer>
                        </div>

                        <div className="divider"></div>

                        <div className="corporate-address">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h3>Contact Support</h3>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Technical Support:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p><a href="mailto:support@checkinanalytics.ca">support@checkinanalytics.ca</a></p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Support Docs/FAQ:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <a href="https://support.checkinanalytics.ca/">https://support.checkinanalytics.ca</a>
                                </GridItem>
                            </GridContainer>
                        </div>

                        <div className="dashboard-button-window">
                            <Button
                                type="button"
                                size="large"
                                fullWidth={true}
                                className="modal-button-bottom dashboard-panel-button edit-company-button"
                                variant="contained"
                                onClick={() => this.toggleUpdateModal(true)}
                            >
                                Edit Company Profile
                            </Button>
                        </div>
                    </div>
                    {/* <h2 className="room-bookings-dashboard-title">Room Bookings</h2> */}
                </GridItem>
                {this.renderReportModal()}
                {this.renderUpdateModal()}
            </GridContainer>
        )
    }

    renderMunicipalityDashboard() {
        setBackgroundPhoto('');
        
        const data = {
            labels: this.props.chartData.labels,
            datasets: [
              {
                label: 'Fees Reported',
                // backgroundColor: '#38b6ff',
                borderColor: '#38b6ff',
                borderWidth: 1,
                // pointHoverBackgroundColor: '#134866',
                pointHoverBorderColor: '#134866',
                lineTension: 0,
                data: this.props.chartData.data
              }
            ]
        };
        
        return (
            <GridContainer>
                <GridItem xs={12} sm={9} md={9}>
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <h2><AlertsIcon className="dashboard-alert-icon" />Payments Due</h2>
                                    <GridContainer>
                                        <GridItem xs={6} sm={6} md={6}>
                                            <p className="fee_past_due_label">0 - 30 Days:</p>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6}> 
                                            <p className="fee_past_due_amount">${this.props.pastDueFees['0-30'].toFixed(2)}</p>
                                        </GridItem>

                                        <GridItem xs={6} sm={6} md={6}>
                                            <p className="fee_past_due_label">31 - 60 Days:</p>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6}> 
                                            <p className="fee_past_due_amount">${this.props.pastDueFees['31-60'].toFixed(2)}</p>
                                        </GridItem>

                                        <GridItem xs={6} sm={6} md={6}>
                                            <p className="fee_past_due_label">61 - 90 Days:</p>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6}> 
                                            <p className="fee_past_due_amount">${this.props.pastDueFees['61-90'].toFixed(2)}</p>
                                        </GridItem>

                                        <GridItem xs={6} sm={6} md={6}>
                                            <p className="fee_past_due_label">90+ Days:</p>
                                        </GridItem>
                                        <GridItem xs={6} sm={6} md={6}> 
                                            <p className="fee_past_due_amount">${this.props.pastDueFees['90+'].toFixed(2)}</p>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <p className="featured-icon-window"><NewReportIcon className="featured-icon" /></p>
                                    <div className="dashboard-button-window">
                                        <Button
                                            type="button"
                                            size="large"
                                            fullWidth={true}
                                            className="modal-button-bottom dashboard-panel-button"
                                            variant="contained"
                                            onClick={() => { this.props.history.push('/reports'); }}
                                        >
                                            Review Levy Reports
                                        </Button>
                                        <Button
                                            type="button"
                                            size="large"
                                            fullWidth={true}
                                            className="modal-button-bottom dashboard-panel-button"
                                            variant="contained"
                                            onClick={() => { this.props.history.push('/summaryreports'); }}
                                        >
                                            Summary Reports
                                        </Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <p className="featured-icon-window"><ReportsIcon className="featured-icon" /></p>
                                    <div className="dashboard-button-window">
                                        <Button
                                            type="button"
                                            size="large"
                                            fullWidth={true}
                                            className="modal-button-bottom dashboard-panel-button"
                                            variant="contained"
                                            onClick={() => { this.props.history.push('/accommodations'); }}
                                        >
                                            Full Accommodation List
                                        </Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className="dashboard-panel">
                                    <h2>Marketing Levy Reported - Past 6 Months</h2>
                                    <Line
                                        data={data}
                                        height={100}
                                        options={{
                                            responsive: true
                                        }}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <div className="dashboard-contact-panel">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h2>Summary</h2>
                                <h3>Levy Fees Reported - Year to Date</h3>
                                <p className="fees_reported">${this.props.feesReportedYTD.toFixed(2)}</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <h3>Levy Fees Unpaid - Year to Date</h3>
                                <p className="fees_unpaid">${this.props.pastDueFeesYTD.toFixed(2)}</p>
                            </GridItem>
                        </GridContainer>

                        <div className="divider"></div>

                        <div className="corporate-address">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h3>Contact Support</h3>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Technical Support:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p><a href="mailto:support@checkinanalytics.ca">support@checkinanalytics.ca</a></p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Support Docs/FAQ:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <a href="https://support.checkinanalytics.ca/">https://support.checkinanalytics.ca</a>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </GridItem>
                {this.renderSummaryReportModal()}
            </GridContainer>
        )
    }

    renderAssociationDashboard() {
        setBackgroundPhoto('');
        
        const data = {
            // labels: ['Nov 2022', 'Dec 2022', 'Jan 2023', 'Feb 2023', 'Mar 2023'],
            labels: this.props.chartData.labels,
            datasets: [
              {
                label: 'Fees Reported',
                // backgroundColor: '#38b6ff',
                borderColor: '#38b6ff',
                borderWidth: 1,
                // pointHoverBackgroundColor: '#134866',
                pointHoverBorderColor: '#134866',
                lineTension: 0,
                data: this.props.chartData.data
                // data: [55, 42, 31, 30, 62]
              }
            ]
        };
        
        return (
            <GridContainer>
                <GridItem xs={12} sm={9} md={9}>
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <h2><MoneyIcon className="dashboard-green-icon" /> Levy Fees Reported (YTD)</h2>
                                    {this.props.cityFeesReported.map((fee) => (
                                        <GridContainer>
                                            <GridItem xs={6} sm={6} md={6}>
                                                <p className="fee_past_due_label">{fee.city}</p>
                                            </GridItem>
                                            <GridItem xs={6} sm={6} md={6}>
                                                <p className="fee_past_due_amount">${parseFloat(fee.total).toFixed(2)}</p>
                                            </GridItem>
                                        </GridContainer>
                                    ))}
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <div className="dashboard-panel">
                                    <p className="featured-icon-window"><NewReportIcon className="featured-icon" /></p>
                                    <div className="dashboard-button-window">
                                        <Button
                                            type="button"
                                            size="large"
                                            fullWidth={true}
                                            className="modal-button-bottom dashboard-panel-button"
                                            variant="contained"
                                            onClick={() => { this.props.history.push('/summaryreports'); }}
                                        >
                                            Summary Reports
                                        </Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className="dashboard-panel">
                                    <h2>Marketing Levy Reported - Past 6 Months</h2>
                                    <Line
                                        data={data}
                                        height={100}
                                        options={{
                                            responsive: true
                                        }}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    <div className="dashboard-contact-panel">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h2>Summary</h2>
                                <h3>Levy Fees Reported - Year to Date</h3>
                                <p className="fees_reported">${this.props.feesReportedYTD.toFixed(2)}</p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <h3>Levy Fees Unpaid - Year to Date</h3>
                                <p className="fees_unpaid">${this.props.pastDueFeesYTD.toFixed(2)}</p>
                            </GridItem>
                        </GridContainer>

                        <div className="divider"></div>

                        <div className="corporate-address">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h3>Contact Support</h3>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Technical Support:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p><a href="mailto:support@checkinanalytics.ca">support@checkinanalytics.ca</a></p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <p className="panel-label">Support Docs/FAQ:</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <a href="https://support.checkinanalytics.ca/">https://support.checkinanalytics.ca</a>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        )
    }

    render() {
        if (this.props.loggedRole.role == "admin" || this.props.loggedRole.role == "vendor" || this.props.loggedRole.role == "vendor_admin") {
            return this.renderVendorDashboard();
        } else if (this.props.loggedRole.role == "municipality" || this.props.loggedRole.role == "municipality_admin") {
            return this.renderMunicipalityDashboard();
        } else if (this.props.loggedRole.role == "association" || this.props.loggedRole.role == "association_admin") {
            return this.renderAssociationDashboard();
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        reportModalOpen: state.modals.reportModal,
        loggedUser: state.auth.user,
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        sitename: state.selectedAssociation.id,
        vendors: state.dashboard.vendors,
        vendor: state.dashboard.vendor,
        totals: state.dashboard.totals,
        chartData: state.dashboard.chartData,
        alerts: state.dashboard.alerts,
        cityFeesReported: state.dashboard.cityFeesReported,
        pastDueFees: state.dashboard.pastDueFees,
        pastDueFeesYTD: state.dashboard.pastDueFeesYTD,
        feesReportedYTD: state.dashboard.feesReportedYTD,
        occupancy: state.dashboard.occupancy,
        total: state.occupancyForm.total,
        total_occupants: state.occupancyForm.total_occupants,
        vendor_id: state.occupancyForm.vendor_id,
        error: state.occupancyForm.error,
        success: state.occupancyForm.dailySuccess,
        reportForm: state.reportForm,
        association_id: state.selectedAssociation.id,
        updateModalOpen: state.modals.vendorsUpdate,
    };
};

export default connect(mapStateToProps)(DashboardPage);