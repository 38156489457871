// the default list of users, which is empty
const usersDefaultState = {
    users: [],
    vendors: [],
    municipalities: [],
    associations: [],
    loading: true
};

export default (state = usersDefaultState, action) => {
    switch (action.type) {

        case 'SET_USERS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_USERS':
            return {
                ...state,
                users: action.users,
                loading: false
            };

        case 'SET_USER_SELECT_DATA':
            return {
                ...state,
                vendors: action.vendors,
                municipalities: action.municipalities,
                associations: action.associations
            };

        default:
            return state;
    }
};