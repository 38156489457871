// the default list of associations, which is empty
const associationsDefaultState = {
    associations: [],
    loading: true
};

export default (state = associationsDefaultState, action) => {
    switch (action.type) {

        case 'SET_ASSOCIATIONS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_ASSOCIATIONS':
            return {
                ...state,
                associations: action.associations,
                loading: false
            };

        default:
            return state;
    }
};