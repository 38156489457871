// the default list of vendors, which is empty
const vendorsDefaultState = {
    vendorsList: [],
    vendors: [],
    reportData: [],
    associations_list: [],
    municipalities_list: [],
    loading: true
};

export default (state = vendorsDefaultState, action) => {
    switch (action.type) {

        case 'SET_VENDORS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_VENDORS':
            return {
                ...state,
                vendors: action.vendors,
                loading: false
            };

        case 'SET_VENDORS_LIST':
            return {
                ...state,
                vendorsList: action.vendorsList,
                loading: false
            };

        case 'SET_ASSOCIATIONS_LIST':
            return {
                ...state,
                associations_list: action.associations_list,
                loading: false
            };

        case 'SET_MUNICIPALITIES_LIST':
            return {
                ...state,
                municipalities_list: action.municipalities_list,
                loading: false
            };

        default:
            return state;
    }
};