import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateVendorReportCsv = (vendor, data) => {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let csvRows = [];

    const date = Date().split(" ");
    let today = new Date;
    let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')

    // we use a date string to generate our filename.
    const dateStr = date[2] + "_" + monthNum + "_" + date[3];
    const generatedDate = monthNames[today.getMonth() + 1] + " " + date[2] + " " + date[3];

    csvRows.push("Accommodation Report");
    csvRows.push(vendor.address)
    csvRows.push(['"Generated on: '+generatedDate+'"']);
    csvRows.push(["Total Room Revenue for Period:", "$"+data.total_room_revenue.toFixed(2)].join(','));
    csvRows.push(["(Deduct): Adjustments for Bad Debts and Refund due to Errors:", "$"+data.adjustment_refunds.toFixed(2)].join(','));
    csvRows.push(["Add: Adjustments to Revenue:", "$"+data.adjustment_revenue.toFixed(2)].join(','));
    csvRows.push(["Adjusted Room Revenue subject to Levy in Reporting Period:", "$"+data.revenue_subject_to_levy.toFixed(2)].join(','));
    csvRows.push(["Total Marketing Levy Due:", "$"+data.total_due.toFixed(2)].join(','));


    csvRows.push(["Report Date", "Status", "Reported Total Due"].join(','));
    data.reports.forEach(report => {
        csvRows.push([
            report.date, 
            '"'+report.status+'"',
            report.total_due
        ].join(','));
    });

    const csvData = csvRows.join('\n');
    download(csvData, `report_accommodation_${dateStr}`);
};

const download = (data, filename) => {
    // Create a Blob with the CSV data and type
    const blob = new Blob([data], { type: 'text/csv' });
    
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    
    // Create an anchor tag for downloading
    const a = document.createElement('a');
    
    // Set the URL and download attribute of the anchor tag
    a.href = url;
    a.download = filename+'.csv';
    
    // Trigger the download by clicking the anchor tag
    a.click();
}

export default generateVendorReportCsv;