import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { addVendor } from '../../actions/vendors';
import { vendorFormUpdate, vendorFormError, vendorFormReset } from '../../actions/vendorForm';
import { openVendorsCreate, closeVendorsCreate } from '../../actions/modals';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class CreateVendor extends React.Component {

    constructor(props) {
        super(props);

        this.toggleModal = this.toggleModal.bind(this);
        this.submitCreateVendor = this.submitCreateVendor.bind(this);
    }

    toggleModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(vendorFormReset());
            this.props.dispatch(openVendorsCreate());
        }
        else {
            this.props.dispatch(closeVendorsCreate());
        }
    }

    submitCreateVendor() {

        // @note: temporarily disabling until we figure out what we want here.
        // perform some error checking
        // if (this.props.vendorForm.name.trim() == "") {

        //     // required fields aren't present
        //     this.props.dispatch(vendorFormError("Please enter a vendor name."));
        // }
        // else if (this.props.vendorForm.licence_number.trim() == "") {

        //     // required fields aren't present
        //     this.props.dispatch(vendorFormError("Please enter a licence number."));
        // }
        // else 
        if (
            this.props.loggedRole.role != "municipality" &&  
            this.props.loggedRole.role != "municipality_admin" &&  
            this.props.vendorForm.association_id == 0
         ) {

            // required fields aren't present
            this.props.dispatch(vendorFormError("Please enter an association."));
        }
        else if (this.props.vendorForm.municipality_id == 0) {

            // required fields aren't present
            this.props.dispatch(vendorFormError("Please enter a municipality."));
        }
        else {

            // add the vendor
            this.props.dispatch(addVendor(this.props.vendorForm));
        }
    }
    
    render() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="association-vendor-add"
                aria-describedby="association-vendor-add"
                open={this.props.modalOpen}
                onClose={() => this.toggleModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Add a Vendor
                    </Typography>
                    <Typography className="modal-description">
                        Please add all required information.
                    </Typography>

                    { this.props.vendorForm.error && (
                        <Typography className="modal-error">
                            { this.props.vendorForm.error }
                        </Typography>
                    )}

                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.name}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    {(this.props.loggedRole.role == "admin" || this.props.loggedRole.role == "municipality" || this.props.loggedRole.role == "municipality_admin") && (
                        <TextField
                            label="Date Started (YYYY-MM-DD)"
                            className="modal-input"
                            fullWidth={true}
                            value={this.props.vendorForm.date_started}
                            onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'date_started', value: e.target.value })); }}
                            margin="normal"
                        />
                    )}

                    <TextField
                        label="Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.address}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'address', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="City"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.city}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'city', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Postal Code"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.postal_code}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'postal_code', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="standard-name"
                        label="Licence #"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.licence_number}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'licence_number', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Corporate Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.corporate_name}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'corporate_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Mailing Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.mailing_address}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'mailing_address', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.owner_name}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'owner_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Email"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.owner_email}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'owner_email', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.phone}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'phone', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Password (leave blank if you do not want to create a user"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.password}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Contact Fax"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.vendorForm.owner_fax}
                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'owner_fax', value: e.target.value })); }}
                        margin="normal"
                    />

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-vendor-role">Vendor Type</InputLabel>
                        <Select
                            value={this.props.vendorForm.vendor_type}
                            onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'vendor_type', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'vendor_type',
                                id: 'new-vendor-type',
                            }}
                        >
                            <MenuItem value="">
                                Select a Vendor Type
                            </MenuItem>
                            <MenuItem value={'accommodation'}>Accommodation</MenuItem>
                            <MenuItem value={'hotel'}>Hotel</MenuItem>
                            <MenuItem value={'motel'}>Motel</MenuItem>
                        </Select>
                    </FormControl>

                    {(this.props.loggedRole.role == "admin") && (
                        <FormControl className="form-control">
                            <InputLabel htmlFor="new-vendor-association">Association</InputLabel>
                            <Select
                                value={this.props.vendorForm.association_id}
                                onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'association_id', value: e.target.value })); }}
                                fullWidth={true}
                                className="modal-select"
                                inputProps={{
                                    name: 'association_id',
                                    id: 'new-vendor-association',
                                }}
                            >
                                <MenuItem value="">
                                    Select an Association
                                </MenuItem>
                                {this.props.associationsList.map((association) => (
                                    <MenuItem key={association.id} value={association.id}>{association.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {(this.props.loggedRole.role == "admin") && (
                        <FormControl className="form-control">
                            <InputLabel htmlFor="new-vendor-municipality">Municipality</InputLabel>
                            <Select
                                value={this.props.vendorForm.municipality_id}
                                onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'municipality_id', value: e.target.value })); }}
                                fullWidth={true}
                                className="modal-select"
                                inputProps={{
                                    name: 'municipality_id',
                                    id: 'new-vendor-municipality',
                                }}
                            >
                                <MenuItem value="">
                                    Select a Municipality
                                </MenuItem>
                                {this.props.municipalitiesList.map((municipality) => (
                                    <MenuItem key={municipality.id} value={municipality.id}>{municipality.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateVendor}>
                        Add Vendor
                    </Button>
                </div>
            </Modal>
        )
    }
};

const mapStateToProps = (state) => {

    return {
        loggedRole: state.auth.role,
        loading: state.vendors.loading,
        vendorForm: state.vendorForm,
        modalOpen: state.modals.vendorsCreate,
        associationsList: state.vendors.associations_list,
        municipalitiesList: state.vendors.municipalities_list,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(CreateVendor));
