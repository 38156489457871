// the default list of reports, which is empty
const reportsDefaultState = {
    reports: [],
    loading: true
};

export default (state = reportsDefaultState, action) => {
    switch (action.type) {

        case 'SET_REPORTS_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'SET_REPORTS':
            return {
                ...state,
                reports: action.reports,
                loading: false
            };

        default:
            return state;
    }
};