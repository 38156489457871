const dashboardDefaultState = {
    alerts: [],
    vendors: [],
    vendor: {
        name: '',
    },
    vendor_id: 0,
    totals: {
        last_month: {
            total: 0,
            change: 0.00
        },
        this_month: {
            total: 0,
            change: 0.00
        },
        next_month: {
            total: 0,
            change: 0.00
        }
    },
    chartData: {
        labels: [],
        data: []
    },
    pastDueFees: {
        '0-30': 0,
        '31-60': 0,
        '61-90': 0,
        '90+': 0,
    },
    cityFeesReported: [],
    pastDueFeesYTD: 0.00,
    feesReportedYTD: 0.00,
    occupancy: [],
    loading: false,
    message: '',
    error: '',
};

export default (state = dashboardDefaultState, action) => {
    switch (action.type) {
        case 'DASHBOARD_INFO_UPDATE':
            return {
                ...state,
                alerts: action.alerts,
                vendors: action.vendors,
                totals: action.totals,
                chartData: action.chartData,
                feesReportedYTD: action.feesReportedYTD,
                occupancy: action.occupancy,
                pastDueFees: action.pastDueFees,
                pastDueFeesYTD: action.pastDueFeesYTD,
                cityFeesReported: action.cityFeesReported,
                vendor_id: action.vendor_id,
                loading: false,
            };

        case 'DASHBOARD_VENDOR_UPDATE':
            return {
                ...state,
                vendor: action.vendor,
                loading: false,
            };

        case 'SET_DASHBOARD_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'SET_DASHBOARD_ALERTS':
            return {
                ...state,
                alerts: action.alerts
            }

        case 'DASHBOARD_ERROR':
            return {
                ...state,
                message: '',
                error: action.message,
                loading: false
            }

        case 'DASHBOARD_RESET':
            return dashboardDefaultState;

        default:
            return state;
    }
};