export const loginFormUpdate = ({ prop, value }) => {

    return {
        type: 'LOGIN_FORM_UPDATE',
        field: { prop, value }
    };
};

export const loginFormError = (message) => {

    return {
        type: 'LOGIN_FORM_ERROR',
        message
    };
};

export const loginFormReset = () => {
    
    return {
        type: 'LOGIN_FORM_RESET',
    };
}