import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { updateVendor } from '../../actions/vendors';
import { reportFormUpdate } from '../../actions/reportForm';
import { openSummaryReportModal, closeSummaryReportModal } from '../../actions/modals';
import generateMunicipalityPDF from "../../utils/reports/generateMunicipalityPDF";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class SummaryReport extends React.Component {

    constructor(props) {
        super(props);

        this.toggleUModal = this.toggleUModal.bind(this);
        this.submitGenerateReport = this.submitGenerateReport.bind(this);
    }

    // componentDidMount() {
    //     this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
    //     verifyLogin(this.props.history);

    //     this.props.dispatch(getVendors());
    // }

    toggleUModal(isOpen, selectedVendor) {
        if (isOpen) {
            this.props.dispatch(openSummaryReportModal());
        }
        else {
            this.props.dispatch(closeSummaryReportModal());
        }
    }

    submitGenerateReport() {

        // add the committee vendor
        // this.props.dispatch(updateVendor(this.props.selectedVendor.id, this.props.vendorForm));
        generateMunicipalityPDF();
        alert("Done!");
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.modalOpen}
                onClose={() => this.toggleUModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Generate a Report
                    </Typography>
                    <Typography className="modal-description">
                        Please select the month and press the button to generate the report.
                    </Typography>

                    {/* { this.props.vendorForm.error && (
                        <Typography className="modal-error">
                            { this.props.vendorForm.error }
                        </Typography>
                    )}

                    { this.props.vendorForm.message && (
                        <Typography className="modal-success">
                            { this.props.vendorForm.message }
                        </Typography>
                    )} */}

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-vendor-role">Vendor Type</InputLabel>
                        <Select
                            value={this.props.reportForm.month}
                            onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'month', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'vendor_type',
                                id: 'new-vendor-type',
                            }}
                        >
                            <MenuItem value="">
                                Month
                            </MenuItem>
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>February</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </FormControl>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitGenerateReport}>
                        Generate Report
                    </Button>
                </div>
            </Modal>
        )
    }
};

const mapStateToProps = (state) => {

    return {
        loggedRole: state.auth.role,
        loading: state.vendors.loading,
        modalOpen: state.modals.summaryReport,
        reportForm: state.reportForm
    };
};

export default connect(mapStateToProps)(withStyles(styles)(SummaryReport));
