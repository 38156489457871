import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';

export const setAnalyticsInfo = (totals) => ({
    type: 'ANALYTICS_INFO_UPDATE',
    totals
});

export const setAnalyticsLoading = () => ({
    type: 'SET_ANALYTICS_LOADING'
});

export const setAnalyticsError = (message) => ({
    type: 'ANALYTICS_ERROR',
    message
});


export const getAnalyticsInfo = (id, vid = 0) => {

    return (dispatch) => {

        dispatch(setAnalyticsLoading());

        let apiStr = API_URL+'/api/analytics/'+id;
        if (vid != 0) {
            apiStr += '/'+vid;
        }

        return axios.get(apiStr)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setAnalyticsInfo(res.data.totals));
                }
                else {
                    console.log("Could not load bookings info!");
                }
            })
            .catch(function(err) {
                console.log(err);
                dispatch(setAnalyticsError("Oops! Looks like we can't load your data. Please ensure that all your information is correct!"));
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};