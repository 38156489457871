import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { bookingsFormUpdate, bookingsFormError, bookingsFormSuccess } from './bookingsForm';
import { configureReportForm } from './reportForm';

export const setBookingsInfo = (vendor,  bookings = [], bookings_date, average_daily_capacity = 0, occupancy_data, totals, report_data, total_occupancy) => ({
    type: 'BOOKINGS_INFO_UPDATE',
    vendor,
    bookings,
    bookings_date,
    average_daily_capacity,
    occupancy_data,
    report_data,
    total_occupancy,
    totals
});

export const setBookingsLoading = () => ({
    type: 'SET_BOOKINGS_LOADING'
});

export const configureDailyBookings = (bookings) => {

    return (dispatch) => {

        let dailyBookings = [], dailyOccupants = [];
        bookings.forEach((theBooking) => {
            let bookingDate = new Date(theBooking.bookings_date);

            dailyBookings[bookingDate.getDate()] = theBooking.total_rooms;
            dailyOccupants[bookingDate.getDate()] = theBooking.total_occupants;
        });

        dispatch(bookingsFormUpdate({ prop: 'bookings', value: dailyBookings }));
        dispatch(bookingsFormUpdate({ prop: 'occupants', value: dailyOccupants }));
    };
};

export const submitDailyBookings = (id, vid, bookings, occupants, bookings_date) => {

    return (dispatch) => {

        return axios.post(API_URL+'/api/bookings/'+id+'/'+vid+'/update', {
            bookings_date,
            bookings,
            occupants
        }).then(function(res) {
            if (res.data.status == 'success') {

                // @TODO: we need to properly handle this by sending back the accommodation data
                dispatch(setBookingsInfo(res.data.vendor, res.data.dailyBookings, res.data.bookings_date, res.data.average_daily_capacity, res.data.occupancy_data, res.data.totals, res.data.report_data, res.data.total_occupancy));
                dispatch(bookingsFormSuccess());
                dispatch(configureDailyBookings(res.data.dailyBookings));
            }
            else {
                dispatch(bookingsFormError(res.data.message));
                console.log(res.data);
                console.log("Could not update the daily bookings!");
            }

        }).catch(function(err) {

            // token is likely invalid; remove it and prompt the user to login again
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            else {
                dispatch(bookingsFormError("We're sorry, but there was an issue processing your request. Our support has been notified of the issue."));
            }
            
        });
    };
};

export const getDailyBookingsInfo = (id, vid, month=null, year=null) => {

    return (dispatch) => {

        dispatch(setBookingsLoading());

        let apiStr = API_URL+'/api/bookings/'+id+'/'+vid;
        if (month && year) {
            apiStr += '/'+month+'/'+year;
        }

        return axios.get(apiStr)
            .then(function(res) {
                if (res.data.status == 'success') {
                    console.log(res.data);
                    dispatch(setBookingsInfo(res.data.vendor, res.data.dailyBookings, res.data.bookings_date, res.data.average_daily_capacity, res.data.occupancy_data, res.data.totals, res.data.report_data, res.data.total_occupancy));

                    // set up the daily bookings
                    dispatch(configureDailyBookings(res.data.dailyBookings));
                    dispatch(configureReportForm(res.data.report_data));
                }
                else {
                    console.log(res.data);
                    console.log("Could not load bookings info!");
                }
            })
            .catch(function(err) {
                console.log(err);
                dispatch(bookingsFormError("Oops! Looks like we can't load your data. Please ensure that all your information is correct!"));
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};