const bookingsDefaultState = {
    bookings_date: new Date(),
    vendor: {},
    bookings: [],
    occupants: [],
    report_data: {},
    average_daily_capacity: 0,
    monthly_totals: {
        total: {
            total: 0,
            change: 0
        },
        occupancy_rate: {
            total: 0.00,
            change: 0.00
        }
    },
    occupancy_data: [0, 0, 0, 0, 0],
    total_occupancy: 0,
    loading: true,
    message: '',
    error: '',
};

export default (state = bookingsDefaultState, action) => {
    switch (action.type) {
        case 'BOOKINGS_INFO_UPDATE':
            return {
                ...state,
                vendor: action.vendor,
                bookings: action.bookings,
                bookings_date: action.bookings_date,
                average_daily_capacity: action.average_daily_capacity,
                report_data: action.report_data,
                monthly_totals: action.totals,
                occupancy_data: action.occupancy_data,
                total_occupancy: action.total_occupancy,
                error: '',
                loading: false,
            };

        case 'SET_BOOKINGS_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'BOOKINGS_ERROR':
            return {
                ...state,
                message: '',
                error: action.message,
                loading: false
            }

        case 'BOOKINGS_RESET':
            return bookingsDefaultState;

        default:
            return state;
    }
};