const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

const monthNumbers = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
];

export function getMonthName(mid) {
    return monthNames[mid];
}

export function getCurrentTime(dateOnly=false) {
    const curDate = new Date();

    let year = curDate.getFullYear(),
        month = (curDate.getMonth() <= 8) ? '0'+(curDate.getMonth()+1) : (curDate.getMonth()+1),
        day = (curDate.getDate() <= 9) ? '0'+curDate.getDate() : curDate.getDate(),
        hour = (curDate.getHours() <= 9) ? '0'+curDate.getHours() : curDate.getHours(),
        minute = (curDate.getMinutes() <= 9) ? '0'+curDate.getMinutes() : curDate.getMinutes(),
        second = (curDate.getSeconds() <= 9) ? '0'+curDate.getSeconds() : curDate.getSeconds();
    
    if (dateOnly) {
        return year+'-'+month+'-'+day;
    }
    else {
        return year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
    }
}

export function getFormattedTime(timeToConvert) {

    if (typeof timeToConvert == "string") {
        timeToConvert = new Date(timeToConvert);
    }

    let year = timeToConvert.getFullYear(),
        month = (timeToConvert.getMonth() <= 8) ? '0'+(timeToConvert.getMonth()+1) : (timeToConvert.getMonth()+1),
        day = (timeToConvert.getDate() <= 9) ? '0'+timeToConvert.getDate() : timeToConvert.getDate(),
        hour = (timeToConvert.getHours() <= 9) ? '0'+timeToConvert.getHours() : timeToConvert.getHours(),
        minute = (timeToConvert.getMinutes() <= 9) ? '0'+timeToConvert.getMinutes() : timeToConvert.getMinutes(),
        second = (timeToConvert.getSeconds() <= 9) ? '0'+timeToConvert.getSeconds() : timeToConvert.getSeconds();
    
    return year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
}

export function getPrettyTime(timeToConvert, dateOnly=false) {

    if (typeof timeToConvert == "string") {
        timeToConvert = new Date(timeToConvert);
    }

    let year = timeToConvert.getFullYear(),
        month = monthNamesShort[timeToConvert.getMonth()],
        day = (timeToConvert.getDate() <= 9) ? '0'+timeToConvert.getDate() : timeToConvert.getDate(),
        hour = (timeToConvert.getHours() <= 9) ? '0'+timeToConvert.getHours() : timeToConvert.getHours(),
        minute = (timeToConvert.getMinutes() <= 9) ? '0'+timeToConvert.getMinutes() : timeToConvert.getMinutes(),
        second = (timeToConvert.getSeconds() <= 9) ? '0'+timeToConvert.getSeconds() : timeToConvert.getSeconds();
    
    if (dateOnly) {
        return month+" "+day+", "+year;
    }
    else {
        return month+" "+day+", "+year+" "+hour+":"+minute+":"+second;
    }
}

export function getGameTime(game_date, game_time) {

    let year = game_date.getFullYear(),
        month = monthNumbers[game_date.getMonth()],
        day = (game_date.getDate() <= 9) ? '0'+game_date.getDate() : game_date.getDate(),
        hour = (game_time.hours() <= 9) ? '0'+game_time.hours() : game_time.hours(),
        minute = (game_time.minutes() <= 9) ? '0'+game_time.minutes() : game_time.minutes(),
        second = "00";

    return year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;
}

export function getFormattedGameTime(game_time) {

    const theGameTime = new Date(game_time);

    let hour = theGameTime.getHours(),
        minute = theGameTime.getMinutes(),
        ampm = hour >= 12 ? 'pm' : 'am';
    
    hour %= 12;
    hour = hour ? hour : 12;
    minute = minute < 10 ? '0'+minute : minute;

    return hour+':'+minute+' '+ampm;
}

export function getInputGameTime(game_time) {

    const theGameTime = new Date(game_time);

    let hour = theGameTime.getHours(),
        minute = theGameTime.getMinutes();
    
    hour %= 12;
    hour = hour ? hour : 12;
    hour = hour < 10 ? '0'+hour : hour;
    minute = minute < 10 ? '0'+minute : minute;

    return hour+':'+minute+':00';
}