import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { occupancyFormUpdate, occupancyFormError } from './occupancyForm';
import { setDashboardLoading } from './dashboard';

export const submitOccupancy = (id, bookings_date, vendor_id, total_rooms, total_occupants) => {

    return (dispatch) => {

        dispatch(setDashboardLoading());

        return axios.post(API_URL+'/api/occupancy/'+id+'/update', {
            bookings_date,
            vendor_id,
            total_rooms,
            total_occupants
        }).then(function(res) {
            if (res.data.status == 'success') {

                // @TODO: we need to properly handle this by sending back the accommodation data
                // dispatch(setDashboard(res.data.totals, res.data.occupancy, res.data.vendors, res.data.vendor_id));
                dispatch(occupancyFormUpdate({ prop: 'dailySuccess', value: true}));
            }
            else {
                dispatch(occupancyFormError(res.data.message));
            }

        }).catch(function(err) {
            // token is likely invalid; remove it and prompt the user to login again
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            else {
                dispatch(occupancyFormError("We're sorry, but there was an issue processing your request. Our support has been notified of the issue."));
            }
            
        });
    };
};