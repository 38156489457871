const reportFormDefaultState = {
    month: '',
    year: '2024',
    search: '',
    totalRooms: 0,
    totalRoomRevenue: 0.00,
    refundErrors: 0.00,
    revenueAdjustments: 0.00,
    adjustedRoomRevenue: 0.00,
    totalLevyDue: 0.00,
    agreed: false,
    showComplete: false,
    start_month: '1',
    start_year: '2024',
    end_month: '1',
    end_year: '2024',
    report_type: 'levy_reported',
    sort_value: '',
    sort_direction: 'asc'
};

export default (state = reportFormDefaultState, action) => {
    
    switch (action.type) {

        case 'REPORT_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'REPORT_FORM_CONFIGURE':
            return {
                ...state,
                closed_for_season: action.data.closed_for_season,
                trip_purpose: {
                    vacationing: action.data.vacationing || 0,
                    business: action.data.business || 0,
                    convention: action.data.convention || 0,
                    motorcoach: action.data.motorcoach || 0,
                    other: action.data.other || 0
                }
            };

        case 'REPORT_FORM_PURPOSE_UPDATE':
            return {
                ...state,
                trip_purpose: {
                    ...state.trip_purpose,
                    [action.field.prop]: action.field.value
                }
            };

        case 'REPORT_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'REPORT_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'REPORT_FORM_RESET':
            return reportFormDefaultState;

        default:
            return state;
    }
};