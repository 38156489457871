import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateSubmissionsTablePdf = (reportData, inputData) => {
  // initialize jsPDF
  const doc = new jsPDF();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // define the columns we want and their titles
  const tableColumn = ["Company", "Owner", "Email", "Total Submissions Due", "Required Submissions"];   

  // define an empty array of rows
  const tableRows = [];

  reportData.forEach(report => {
    const row = [
        report.name,
        report.owner_name,
        report.owner_email,
        report.total_reports,
        report.report_dates
    ];
    // push each tickcet's info into a row
    tableRows.push(row);
  });

  const date = Date().split(" ");
  let today = new Date;
  let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')
  // we use a date string to generate our filename.
  const dateStr = date[2] + "_" + monthNum + "_" + date[3];
  const generatedDate = monthNames[today.getMonth() + 1] + " " + date[2] + ", " + date[3];
  

  doc.text("Submissions Past Due Report", 65, 14);

doc.setFontSize(14);

doc.text(monthNames[inputData.start_month - 1] + " " + inputData.start_year + " - " + monthNames[inputData.end_month - 1] + " " + inputData.end_year, 79, 22);

doc.setFontSize(12);
doc.text("Generated on: "+generatedDate, 76, 28);

doc.autoTable(tableColumn, tableRows, { startY: 35 });
  
  doc.save(`report_submissionsPastDue_${dateStr}.pdf`);

};

export default generateSubmissionsTablePdf;