// Get visible vendors

export default (vendors, { search = '', sort_value='id', sort_direction='asc'}) => {

    return vendors.filter((vendor) => {
        let textNameMatch = false;

        textNameMatch = vendor.name.toLowerCase().includes(search.toLowerCase()) || vendor.licence_number.toLowerCase().includes(search.toLowerCase()) || vendor.owner_name.toLowerCase().includes(search.toLowerCase());
        return textNameMatch;

    }).sort((a, b) => {

        let sortValues = sort_value.split('.');
        if (sort_direction === 'desc') {

            if (sortValues.length == 2) {
                return a[sortValues[0]][sortValues[1]] <= b[sortValues[0]][sortValues[1]] ? 1 : -1;
            }

            return a[sort_value] <= b[sort_value] ? 1 : -1;
        }

        if (sortValues.length == 2) {
            return a[sortValues[0]][sortValues[1]] > b[sortValues[0]][sortValues[1]] ? 1 : -1;
        }
        
        return a[sort_value] > b[sort_value] ? 1 : -1;
    });
}; 