import React from 'react';
import Favicon from 'react-favicon';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AccommodationsPage from '../components/AccommodationsPage';
import DashboardPage from '../components/DashboardPage';
import DailyBookingsPage from '../components/DailyBookingsPage';
import AnalyticsPage from '../components/AnalyticsPage';
import UsersPage from '../components/UsersPage';
import ReportsPage from '../components/ReportsPage';
import SummaryReportsPage from '../components/SummaryReportsPage';
import VendorsPage from '../components/VendorsPage';
import AssociationsPage from '../components/AssociationsPage';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/LoginPage';
import Header from '../components/common/Header';
import LeftSidebar from '../components/common/LeftSidebar';
import UpdateProfile from '../components/modal/UpdateProfile';

const MainRouter = (props) => {
    return (
        <BrowserRouter>
            <div>
                <Favicon url="/favicon.ico" />
                <Header />
                <LeftSidebar />
                <Switch>
                    <Route path="/" component={LoginPage} exact={true} />
                    <Route path="/login" component={LoginPage} exact={true} />
                    <Route path="/dash/" component={DashboardPage} exact={true} />
                    <Route path="/dash/:vid/" component={DashboardPage} exact={true} />
                    <Route path="/:id/bookings/:vid/" component={DailyBookingsPage} exact={true} />
                    <Route path="/:id/bookings/:vid/:month/:year/" component={DailyBookingsPage} exact={true} />
                    <Route path="/:id/analytics/" component={AnalyticsPage} exact={true} />
                    <Route path="/:id/analytics/:vid/" component={AnalyticsPage} exact={true} />
                    <Route path="/users/" component={UsersPage} exact={true} />
                    <Route path="/reports/" component={ReportsPage} exact={true} />
                    <Route path="/vendors/" component={VendorsPage} exact={true} />
                    <Route path="/associations/" component={AssociationsPage} exact={true} />
                    <Route path="/accommodations/" component={AccommodationsPage} exact={true} />
                    <Route path="/summaryreports/" component={SummaryReportsPage} exact={true} />
                    <Route component={NotFoundPage} />
                </Switch>
                <UpdateProfile />
            </div>
        </BrowserRouter>
    );
}

export default MainRouter;