import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import { getReports, updatePaymentStatus, removeReport, setReports, generateSubmittedReport } from '../actions/reports';
import { generateVendorReport } from '../actions/vendors';
import { reportFormUpdate, updateLeveeReport, reportFormReset } from '../actions/reportForm';
import { openReportModal, closeReportModal } from '../actions/modals';
import { setSelectedAssociationID } from '../actions/selectedAssociation';
import filterReports from '../selectors/reports';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class ReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.reportFormUpdate = this.reportFormUpdate.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.updatePaymentStatus = this.updatePaymentStatus.bind(this);
        this.removeReport = this.removeReport.bind(this);
        this.reportFormSort = this.reportFormSort.bind(this);
        this.searchReports = this.searchReports.bind(this);

        this.state = {
            selectedReport: {}
        };
    }

    reportFormUpdate({ prop, value }) {

        this.props.dispatch(reportFormUpdate({ prop, value }));

        let adjustedRoomRevenue = 
            ((prop === 'totalRoomRevenue') ? parseFloat(value) : parseFloat(this.props.reportForm.totalRoomRevenue)) - 
            ((prop === 'refundErrors') ? parseFloat(value) : parseFloat(this.props.reportForm.refundErrors)) + 
            ((prop === 'revenueAdjustments') ? parseFloat(value) : parseFloat(this.props.reportForm.revenueAdjustments));
        let totalLevyDue = adjustedRoomRevenue * 0.03;
        this.props.dispatch(reportFormUpdate({ prop: 'adjustedRoomRevenue', value: adjustedRoomRevenue }));
        this.props.dispatch(reportFormUpdate({ prop: 'totalLevyDue', value: totalLevyDue }));
    }

    searchReports() {
        this.props.dispatch(getReports(this.props.reportForm));
    }

    reportFormSort(reports, reportForm, { prop, value }) {

        this.props.dispatch(reportFormUpdate({ prop, value} ));

        // sort the report form data and update the list
        if (value && value != '') {
            let sortValue = value;
            let sortDirection = 'asc';
            if (this.props.reportForm.sort_direction) {
                sortDirection = this.props.reportForm.sort_direction;
            }

            let sortedReports = reports.sort((a, b) => {

                if (sortDirection == 'desc') {
                    return a.vendor[sortValue] <= b.vendor[sortValue] ? 1 : -1;
                }

                return a.vendor[sortValue] > b.vendor[sortValue] ? 1 : -1;
            });

            this.props.dispatch(setReports(sortedReports));
            this.forceUpdate();
        }
    }

    removeReport({ id }) {
        if (confirm("Are you sure you want to remove this committee report? This cannot be undone")) {
            this.props.dispatch(removeReport(id, this.props.match.params.id));
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getReports({}));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleUpdateModal(isOpen, selectedReport) {
        console.log("Selected Report");
        console.log(selectedReport);
        if (isOpen) {
            this.setState(() => ({
                selectedReport
            }));
            this.props.dispatch(reportFormReset());
            this.props.dispatch(reportFormUpdate({ prop: 'month', value: selectedReport.raw_date.substring(5, 7) }));
            this.props.dispatch(reportFormUpdate({ prop: 'year', value: selectedReport.raw_date.substring(0, 4) }));
            this.props.dispatch(reportFormUpdate({ prop: 'totalRooms', value: selectedReport.rooms_rented }));
            this.props.dispatch(reportFormUpdate({ prop: 'totalRoomRevenue', value: selectedReport.total_room_revenue }));
            this.props.dispatch(reportFormUpdate({ prop: 'refundErrors', value: selectedReport.adjustment_refunds }));
            this.props.dispatch(reportFormUpdate({ prop: 'revenueAdjustments', value: selectedReport.adjustment_revenue }));
            this.props.dispatch(reportFormUpdate({ prop: 'adjustedRoomRevenue', value: selectedReport.revenue_subject_to_levy }));
            this.props.dispatch(reportFormUpdate({ prop: 'totalLevyDue', value: selectedReport.total_due }));
            this.props.dispatch(openReportModal());
        }
        else {
            this.props.dispatch(closeReportModal());
        }
    }

    renderUpdatePaymentButton(selectedReport) {
        if (selectedReport.payment_complete) {
            return (
                <Button variant="contained" fullWidth={true} color="secondary" className="modal-button-bottom" onClick={() => { this.props.dispatch(updatePaymentStatus(selectedReport.id, { payment_complete: false })) }}>
                    Set to 'Unpaid'
                </Button>
            );
        } else {
            return (
                <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => { this.props.dispatch(updatePaymentStatus(selectedReport.id, { payment_complete: true })) }}>
                    Set to 'Paid'
                </Button>
            );
        }
    }


    updatePaymentStatus() {

        // add the committee report
        this.props.dispatch(updatePaymentStatus(this.state.selectedReport.id, this.props.reportForm));
    }

    renderReportStatus(isComplete) {
        return (isComplete) ? 'Paid' : 'Not Paid';
    }

    renderPaymentCompleteModal() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.reportModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Update Payment Status
                    </Typography>
                    <Typography className="modal-description">
                        Please update the payment status as required, and click the update button to continue.
                    </Typography>

                    { this.props.reportForm.error && (
                        <Typography className="modal-error">
                            { this.props.reportForm.error }
                        </Typography>
                    )}

                    { this.props.reportForm.message && (
                        <Typography className="modal-success">
                            { this.props.reportForm.message }
                        </Typography>
                    )}

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-report-role">Payment Status</InputLabel>
                        <Select
                            value={this.props.reportForm.payment_complete}
                            onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'payment_complete', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'role',
                                id: 'new-report-role',
                            }}
                        >
                            <MenuItem value="">
                                Select a Role
                            </MenuItem>
                            <MenuItem value={0}>Not Paid</MenuItem>
                            <MenuItem value={1}>Paid</MenuItem>
                        </Select>
                    </FormControl>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updatePaymentStatus}>
                        Update Payment Status
                    </Button>
                </div>
            </Modal>
        )
    }

    renderReportModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="association-user-add"
                aria-describedby="association-user-add"
                open={this.props.reportModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Monthly Levy Report
                    </Typography>
                    
                    <div>
                        { this.props.reportForm.showComplete && (
                            <Typography className="modal-success">
                                Levy Report Updated!
                            </Typography>
                        )}

                        { !this.props.reportForm.showComplete && this.props.reportForm.error && (
                            <Typography className="modal-error">
                                { this.props.reportForm.error }
                            </Typography>
                        )}

                        <Typography className="modal-description">
                            Reporting Period - Select Month & Year
                        </Typography>

                        <GridContainer justify="center" alignItems="center">
                            <GridItem xs={12} sm={6} md={6}>
                                <InputLabel htmlFor="levee-report-month">Month</InputLabel>
                                <Select
                                    value={this.props.reportForm.month}
                                    onChange={(e) => { this.reportFormUpdate({ prop: 'month', value: e.target.value }); }}
                                    fullWidth={true}
                                    className="modal-select"
                                    inputProps={{
                                        name: 'month',
                                        id: 'levee-report-month',
                                    }}
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        Select a Month
                                    </MenuItem>
                                    <MenuItem value={'01'}>January</MenuItem>
                                    <MenuItem value={'02'}>February</MenuItem>
                                    <MenuItem value={'03'}>March</MenuItem>
                                    <MenuItem value={'04'}>April</MenuItem>
                                    <MenuItem value={'05'}>May</MenuItem>
                                    <MenuItem value={'06'}>June</MenuItem>
                                    <MenuItem value={'07'}>July</MenuItem>
                                    <MenuItem value={'08'}>August</MenuItem>
                                    <MenuItem value={'09'}>September</MenuItem>
                                    <MenuItem value={'10'}>October</MenuItem>
                                    <MenuItem value={'11'}>November</MenuItem>
                                    <MenuItem value={'12'}>December</MenuItem>
                                </Select>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <InputLabel htmlFor="levee-report-year">Year</InputLabel>
                                <Select
                                    value={this.props.reportForm.year}
                                    onChange={(e) => { this.reportFormUpdate({ prop: 'year', value: e.target.value }); }}
                                    fullWidth={true}
                                    className="modal-select"
                                    inputProps={{
                                        name: 'year',
                                        id: 'levee-report-year',
                                    }}
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        Select a Year
                                    </MenuItem>
                                    <MenuItem value={'2024'}>2024</MenuItem>
                                    <MenuItem value={'2025'}>2025</MenuItem>
                                </Select>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6}>
                                <Typography className="modal-description">
                                    Total Rooms Rented
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <TextField
                                    className="modal-input"
                                    fullWidth={true}
                                    type="number"
                                    value={this.props.reportForm.totalRooms}
                                    onChange={(e) => { this.reportFormUpdate({ prop: 'totalRooms', value: e.target.value }); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Typography className="modal-description">
                                    Total Room Revenue for Period
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <TextField
                                    className="modal-input"
                                    fullWidth={true}
                                    type="number"
                                    value={this.props.reportForm.totalRoomRevenue}
                                    onChange={(e) => { this.reportFormUpdate({ prop: 'totalRoomRevenue', value: e.target.value }); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Typography className="modal-description">
                                    (Deduct): Adjustments for Bad Debts and Refund due to Errors
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <TextField
                                    className="modal-input modal-input-deduction"
                                    fullWidth={true}
                                    type="number"
                                    value={this.props.reportForm.refundErrors}
                                    onChange={(e) => { this.reportFormUpdate({ prop: 'refundErrors', value: e.target.value }); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Typography className="modal-description">
                                    Add: Adjustments to Revenue
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <TextField
                                    className="modal-input"
                                    fullWidth={true}
                                    type="number"
                                    value={this.props.reportForm.revenueAdjustments}
                                    onChange={(e) => { this.reportFormUpdate({ prop: 'revenueAdjustments', value: e.target.value }); }}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Typography className="modal-description">
                                    Adjusted Room Revenue subject to Levy in reporting period
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <TextField
                                    className="modal-input"
                                    fullWidth={true}
                                    type="number"
                                    value={parseFloat(this.props.reportForm.adjustedRoomRevenue).toFixed(2)}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Typography className="modal-description">
                                    Total Marketing Levy Due:
                                </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <TextField
                                    className="modal-input"
                                    fullWidth={true}
                                    type="number"
                                    value={parseFloat(this.props.reportForm.totalLevyDue).toFixed(2)}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </GridItem>
                        </GridContainer>

                        <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={() => this.props.dispatch(updateLeveeReport(this.state.selectedReport.id, this.props.reportForm))}>
                            Submit Report
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }

    renderReportsList() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>{this.props.association.name}</h4>
                            <p className={classes.cardCategoryWhite}>
                            Reports
                            </p>
                        </CardHeader>
                        <CardBody>
                        <GridContainer className="summary-report-menu">
                                <GridItem xs={12} sm={12} md={2}>
                                    <InputLabel htmlFor="start-month">Start Month</InputLabel>
                                    <Select
                                        value={this.props.reportForm.start_month}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'start_month', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'start_month',
                                            id: 'start-month',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Month
                                        </MenuItem>
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                <InputLabel htmlFor="start-year">Start Year</InputLabel>
                                    <Select
                                        value={this.props.reportForm.start_year}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'start_year', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'start_year',
                                            id: 'start-year',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Year
                                        </MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2025}>2025</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <InputLabel htmlFor="end-month">End Month</InputLabel>
                                    <Select
                                        value={this.props.reportForm.end_month}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'end_month', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'end_month',
                                            id: 'end-month',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Month
                                        </MenuItem>
                                        <MenuItem value={1}>January</MenuItem>
                                        <MenuItem value={2}>February</MenuItem>
                                        <MenuItem value={3}>March</MenuItem>
                                        <MenuItem value={4}>April</MenuItem>
                                        <MenuItem value={5}>May</MenuItem>
                                        <MenuItem value={6}>June</MenuItem>
                                        <MenuItem value={7}>July</MenuItem>
                                        <MenuItem value={8}>August</MenuItem>
                                        <MenuItem value={9}>September</MenuItem>
                                        <MenuItem value={10}>October</MenuItem>
                                        <MenuItem value={11}>November</MenuItem>
                                        <MenuItem value={12}>December</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}>
                                    <InputLabel htmlFor="end-year">End Year</InputLabel>
                                    <Select
                                        value={this.props.reportForm.end_year}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'end_year', value: e.target.value })); }}
                                        fullWidth={true}
                                        variant="outlined"
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'end_year',
                                            id: 'end-year',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Year
                                        </MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2025}>2025</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <InputLabel htmlFor="sort-value">Sort By</InputLabel>
                                    <Select
                                        value={this.props.reportForm.sort_value}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'sort_value', value: e.target.value })); }}
                                        fullWidth={true}
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'sort_value',
                                            id: 'sort-value',
                                        }}
                                    >
                                        <MenuItem value={''}>
                                            Default
                                        </MenuItem>
                                        <MenuItem value={'date'}>Report Period</MenuItem>
                                        <MenuItem value={'vendor.name'}>Accommodation</MenuItem>
                                        <MenuItem value={'vendor.address'}>Address</MenuItem>
                                        <MenuItem value={'vendor.owner_name'}>Contact Name</MenuItem>
                                        <MenuItem value={'vendor.owner_email'}>Contact Email</MenuItem>
                                        <MenuItem value={'total_due'}>Total Levy Due</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <InputLabel htmlFor="sort-direction">Direction</InputLabel>
                                    <Select
                                        value={this.props.reportForm.sort_direction}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'sort_direction', value: e.target.value })); }}
                                        fullWidth={true}
                                        className="summary-report-menu-input"
                                        inputProps={{
                                            name: 'sort_direction',
                                            id: 'sort-direction',
                                        }}
                                    >
                                        <MenuItem value="">
                                            Year
                                        </MenuItem>
                                        <MenuItem value={'asc'}>Ascending</MenuItem>
                                        <MenuItem value={'desc'}>Descending</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <Button
                                        type="button"
                                        size="large"
                                        fullWidth={true}
                                        className="summary-report-menu-input"
                                        variant="contained"
                                        onClick={this.searchReports}
                                    >
                                        Search Reports
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="text"
                                        value={this.props.reportForm.search}
                                        onChange={(e) => { this.props.dispatch(reportFormUpdate({ prop: 'search', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                            {(this.props.loggedRole.role == 'vendor' || this.props.loggedRole.role == 'vendor_admin') && (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            className="download-pdf-button" 
                                            onClick={() => { this.props.dispatch(generateVendorReport(this.props.vendor)) }}
                                        >
                                            Download PDF
                                        </Button>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            className="download-pdf-button" 
                                            onClick={() => { this.props.dispatch(generateVendorReport(this.props.vendor, true)) }}
                                        >
                                            Download CSV
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            )}
                            <Table
                                tableHead={(this.props.loggedRole.role == "admin" || this.props.loggedRole.role == "municipality" || this.props.loggedRole.role == "municipality_admin") ? ["Report Period", "Accomodation", "Licence Number", "Address", "Contact Name", "Contact Email", "Total Levy Due", "Payment Method", "Status", ""] : ["Report Period", "Accomodation", "Licence Number", "Address", "Contact Name", "Contact Email", "Total Levy Due", "Payment Method", "Status"]}
                                tableData={filterReports(this.props.reports, {search: this.props.reportForm.search, sort_value: this.props.reportForm.sort_value, sort_direction: this.props.reportForm.sort_direction}).map((report) => [
                                    report.date, 
                                    report.vendor.name,
                                    report.vendor.licence_number,
                                    report.vendor.address,
                                    report.vendor.owner_name,
                                    report.vendor.owner_email,
                                    report.total_due, 
                                    report.payment_method,
                                    this.renderReportStatus(report.payment_complete),
                                    (
                                        <div>
                                            <Icon className="fa fa-file-invoice manager-table-icon manager-table-edit" onClick={() => generateSubmittedReport(report)} />
                                            {(this.props.loggedRole.role == "admin") && <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, report)} />}
                                            {(this.props.loggedRole.role == "municipality" || this.props.loggedRole.role == "municipality_admin") && this.renderUpdatePaymentButton(report)}
                                        </div>
                                    )
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderReportsList()}
                {this.renderReportModal()}
                {(this.props.loggedRole.role == "municipality" || this.props.loggedRole.role == "municipality_admin") && this.renderPaymentCompleteModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        loggedUser: state.auth.user,
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.reports.loading,
        reports: state.reports.reports,
        reportForm: state.reportForm,
        reportModalOpen: state.modals.reportModal,
        association: state.selectedAssociation.association,
        vendor: state.dashboard.vendor
    };
};

export default connect(mapStateToProps)(withStyles(styles)(ReportsPage));
