const profileFormDefaultState = {
    first_name: '',
    last_name: '',
    password: '',
    password_confirm: '',
    loading: false,
    error: '',
    message: ''
};

export default (state = profileFormDefaultState, action) => {
    
    switch (action.type) {

        case 'PROFILE_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'PROFILE_FORM_LOAD':
            return {
                ...state,
                ...action.profile
            };

        case 'PROFILE_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'PROFILE_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'PROFILE_FORM_RESET':
            return profileFormDefaultState;

        default:
            return state;
    }
};