import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { reportFormSuccess, reportFormError } from '../actions/reportForm';
import { closeReportsCreate } from './modals';

export const setSummaryReportData = (reportData, reportType) => ({
    type: 'SET_REPORT_DATA',
    reportData,
    reportType
});

export const setSummaryReportsLoading = () => ({
    type: 'SET_SUMMARY_REPORTS_LOADING'
});

export const getSummaryReportData = (municipality_id, association_id, { start_month, start_year, end_month, end_year, report_type }) => {

    return (dispatch) => {

        console.log(association_id);

        dispatch(setSummaryReportsLoading());
        return axios.post(API_URL+'/api/summaryreport', {
            association_id,
            municipality_id,
            start_month,
            start_year,
            end_month,
            end_year,
            report_type
        })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setSummaryReportData(res.data.reportData, report_type));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get reports!");
                    alert("Could not get reports!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the report to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};
