import axios from 'axios';
import { API_URL } from '../config/constants';
import setAuthToken from '../utils/setAuthToken';
import jwt from 'jsonwebtoken';
import { loginFormReset, loginFormError } from './loginForm';
import { profileFormLoad, profileFormError, profileFormSuccess } from './profileForm';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import { setSelectedAssociation } from './selectedAssociation';

export const setCurrentUser = (data) => {

    return {
        type: 'SET_CURRENT_USER',
        user: data.data.user,
        role: data.data.role
    }
}

export const removeCurrentUser = () => {
    return {
        type: 'REMOVE_CURRENT_USER'
    }
}

export const authorize = (data = {}) => {
    return (dispatch) => {

        let token = localStorage.getItem('checkinToken');

        // is the token present?
        if (token) {
            const decodedToken = jwt.decode(token);

            dispatch(profileFormLoad({
                first_name: decodedToken.data.user.first_name,
                last_name: decodedToken.data.user.last_name,
            }));
            
            setAuthToken(token);
            dispatch(setCurrentUser(decodedToken));
        }
    };
}

export const updateProfile = ({first_name, last_name, password, password_confirm}) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/profile', {first_name, last_name, password, password_confirm})
            .then(function(res) {
                if (res.data.status == 'success') {
                    const token = res.data.token;
                    localStorage.setItem('checkinToken', token);

                    setAuthToken(token);
                    dispatch(setCurrentUser(jwt.decode(token)));
                    
                    dispatch(profileFormLoad({
                        first_name: res.data.user.first_name,
                        last_name: res.data.user.last_name,
                    }));

                    dispatch(profileFormSuccess('Profile Updated!'));
                }
                else {
                    dispatch(profileFormError(res.data.message));
                }
            })
            .catch(function(err) {
                console.log(err);
                alert("We're sorry, but there was an issue with profile update. Please try again shortly.");
            });
    };
}

export const login = (emailAddress, password, rememberMe, history) => {
    return (dispatch) => {
        return axios.post(API_URL+'/api/login', {email: emailAddress, password, rememberMe})
            .then(function(res) {
                if (res.data.status == 'success') {
                    const token = res.data.token;
                    localStorage.setItem('checkinToken', token);
                    setAuthToken(token);
                    dispatch(setCurrentUser(jwt.decode(token)));
                    dispatch(setSelectedAssociation(res.data.association));

                    dispatch(profileFormLoad({
                        first_name: res.data.user.first_name,
                        last_name: res.data.user.last_name,
                    }));

                    dispatch(loginFormReset());
                    setBackgroundPhoto('');
                    history.push('/dash');
                }
                else {
                    dispatch(loginFormError(res.data.message));
                }
            })
            .catch(function(err) {
                console.log(err);
                alert("We're sorry, but there was an issue with authorization. Please try again shortly.");
            });
    };
}

export const logout = (history) => {
    return (dispatch) => {
        localStorage.removeItem('checkinToken');
        dispatch(removeCurrentUser());
        history.push('/');
    };
}