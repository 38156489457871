export default function setBackgroundPhoto(scope = '') {
    document.body.classList.remove('bg-login');

    switch (scope) {

        case 'login':
            document.body.classList.add('bg-login');
            break;

        default:
            return;
    }
}