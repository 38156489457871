export const openLeftSidebar = () => {

    return {
        type: 'OPEN_LEFT_SIDEBAR',
    };
};

export const closeLeftSidebar = () => {

    return {
        type: 'CLOSE_LEFT_SIDEBAR',
    };
};

export const openRightSidebar = () => {

    return {
        type: 'OPEN_RIGHT_SIDEBAR',
    };
};

export const closeRightSidebar = () => {

    return {
        type: 'CLOSE_RIGHT_SIDEBAR',
    };
};