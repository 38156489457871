import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { authorize } from './actions/auth';
import MainRouter from './routes/MainRouter';
import configStore from './store/configStore';
import 'normalize.css/normalize.css';
import './styles/style.scss';

// create the Redux Store (using our imported store)
const store = configStore();
store.dispatch(authorize());

const appTemplate = (
    <Provider store={store}>
        <MainRouter />
    </Provider>
);

ReactDOM.render(appTemplate, document.getElementById('app'));