import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { setReports } from './reports';
import { setDashboardAlerts } from './dashboard';

export const reportFormUpdate = ({ prop, value }) => {

    return {
        type: 'REPORT_FORM_UPDATE',
        field: { prop, value }
    };
};

export const reportFormPurposeUpdate = ({ prop, value }) => {

    return {
        type: 'REPORT_FORM_PURPOSE_UPDATE',
        field: { prop, value }
    };
};

export const reportFormError = (message) => {

    return {
        type: 'REPORT_FORM_ERROR',
        message
    };
};

export const reportFormSuccess = (message) => {

    return {
        type: 'REPORT_FORM_SUCCESS',
        message
    };
};

export const reportFormReset = () => {
    
    return {
        type: 'REPORT_FORM_RESET',
    };
};

export const reportFormSort = (reports, formData, { prop, value }) => {

    return (dispatch) => {

        // update the form value
        dispatch(reportFormUpdate({ prop, value} ));

        // sort the report form data and update the list
        if (value && value != '') {
            let sortValue = value;
            let sortDirection = 'asc';
            if (formData.sort_direction) {
                sortDirection = formData.sort_direction;
            }

            let sortedReports = reports.sort((a, b) => {

                if (sortDirection == 'desc') {
                    return a.vendor[sortValue] <= b.vendor[sortValue] ? 1 : -1;
                }

                return a.vendor[sortValue] > b.vendor[sortValue] ? 1 : -1;
            });

            dispatch(setReports(sortedReports));
            
        }

    };
};

export const submitLeveeReport = (vendor_id, report) => {

    return (dispatch) => {

        // dispatch(setReportLoading());
        return axios.post(API_URL+'/api/reports', {
            vendorId: vendor_id,
            report
        })
        .then(function(res) {
            if (res.data.status == 'success') {
                dispatch(reportFormUpdate({prop: 'showComplete', value: true}));
                dispatch(setDashboardAlerts(res.data.alerts));
            }
            else {
                dispatch(reportFormError(res.data.message));
            }
        })
        .catch(function(err) {
            
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(reportFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};

export const updateLeveeReport = (reportId, report) => {

    return (dispatch) => {

        // dispatch(setReportLoading());
        return axios.post(API_URL+'/api/reports/'+reportId, {
            report
        })
        .then(function(res) {
            if (res.data.status == 'success') {
                dispatch(reportFormUpdate({prop: 'showComplete', value: true}));
                window.location.reload();
            }
            else {
                dispatch(reportFormError(res.data.message));
            }
        })
        .catch(function(err) {
            
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(reportFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};


export const configureReportForm = (reportData) => {

    if (reportData.vendor_id) {
        return {
            type: 'REPORT_FORM_CONFIGURE',
            data: reportData
        }
    }
    else {
        return {
            type: 'REPORT_FORM_RESET',
        };
    }
}

export const updateReportData = (id, vendor_id, bookings_date, data) => {

    return (dispatch) => {

        // dispatch(setBookingsLoading());

        // modify the bookings date to the first
        const dateMatch = bookings_date.match(/^(\d\d\d\d)\-(\d\d)\-\d\d$/);
        let bookings_date_first = bookings_date;
        if (dateMatch) {
            bookings_date_first = dateMatch[1]+"-"+dateMatch[2]+"-01";
        }

        return axios.post(API_URL+'/api/bookings/'+id+'/report/update', {
            bookings_date: bookings_date_first,
            vendor_id,
            data
        }).then(function(res) {
            if (res.data.status == 'success') {

                // @TODO: we need to update this data in redux still
                // dispatch(occupancyFormUpdate({ prop: 'dailySuccess', value: true}));
            }
            else {
                dispatch(reportFormError(res.data.message));
            }

        }).catch(function(err) {
            // token is likely invalid; remove it and prompt the user to login again
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            else {
                dispatch(reportFormError("We're sorry, but there was an issue processing your request. Our support has been notified of the issue."));
            }
            
        });
    };
};