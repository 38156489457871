export const profileFormUpdate = ({ prop, value }) => {

    return {
        type: 'PROFILE_FORM_UPDATE',
        field: { prop, value }
    };
};

export const profileFormLoad = (profile) => {

    return {
        type: 'PROFILE_FORM_LOAD',
        profile
    };
};

export const profileFormError = (message) => {

    return {
        type: 'PROFILE_FORM_ERROR',
        message
    };
};

export const profileFormSuccess = (message) => {

    return {
        type: 'PROFILE_FORM_SUCCESS',
        message
    };
};

export const profileFormReset = () => {
    
    return {
        type: 'PROFILE_FORM_RESET',
    };
};