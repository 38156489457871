const modalsDefaultState = {
    usersCreate: false,
    usersUpdate: false,
    reportModal: false,
    summaryReport: false,
    vendorsCreate: false,
    vendorsUpdate: false,
    municipalitiesCreate: false,
    municipalitiesUpdate: false,
    associationsCreate: false,
    associationsUpdate: false,
    profileUpdate: false,
};

export default (state = modalsDefaultState, action) => {
    switch (action.type) {

        case 'OPEN_PROFILE_UPDATE_MODAL':
            return {
                ...state,
                profileUpdate: true
            };

        case 'CLOSE_PROFILE_UPDATE_MODAL':
            return {
                ...state,
                profileUpdate: false
            };

        case 'OPEN_USERS_CREATE_MODAL':
            return {
                ...state,
                usersCreate: true
            };

        case 'CLOSE_USERS_CREATE_MODAL':
            return {
                ...state,
                usersCreate: false
            };

        case 'OPEN_USERS_UPDATE_MODAL':
            return {
                ...state,
                usersUpdate: true
            };

        case 'CLOSE_USERS_UPDATE_MODAL':
            return {
                ...state,
                usersUpdate: false
            };

        case 'OPEN_REPORT_MODAL':
            return {
                ...state,
                reportModal: true
            };

        case 'CLOSE_REPORT_MODAL':
            return {
                ...state,
                reportModal: false
            };

        case 'OPEN_SUMMARY_REPORT_MODAL':
            return {
                ...state,
                summaryReport: true
            };

        case 'CLOSE_SUMMARY_REPORT_MODAL':
            return {
                ...state,
                summaryReport: false
            };

        case 'OPEN_VENDORS_CREATE_MODAL':
            return {
                ...state,
                vendorsCreate: true
            };

        case 'CLOSE_VENDORS_CREATE_MODAL':
            return {
                ...state,
                vendorsCreate: false
            };

        case 'OPEN_VENDORS_UPDATE_MODAL':
            return {
                ...state,
                vendorsUpdate: true
            };

        case 'CLOSE_VENDORS_UPDATE_MODAL':
            return {
                ...state,
                vendorsUpdate: false
            };

        case 'OPEN_MUNICIPALITIES_CREATE_MODAL':
            return {
                ...state,
                municipalitiesCreate: true
            };

        case 'CLOSE_MUNICIPALITIES_CREATE_MODAL':
            return {
                ...state,
                municipalitiesCreate: false
            };

        case 'OPEN_MUNICIPALITIES_UPDATE_MODAL':
            return {
                ...state,
                municipalitiesUpdate: true
            };

        case 'CLOSE_MUNICIPALITIES_UPDATE_MODAL':
            return {
                ...state,
                municipalitiesUpdate: false
            };

        case 'OPEN_ASSOCIATIONS_CREATE_MODAL':
            return {
                ...state,
                associationsCreate: true
            };

        case 'CLOSE_ASSOCIATIONS_CREATE_MODAL':
            return {
                ...state,
                associationsCreate: false
            };

        case 'OPEN_ASSOCIATIONS_UPDATE_MODAL':
            return {
                ...state,
                associationsUpdate: true
            };

        case 'CLOSE_ASSOCIATIONS_UPDATE_MODAL':
            return {
                ...state,
                associationsUpdate: false
            };

        case 'RESET_MODALS':
            return modalsDefaultState;

        default:
            return state;
    }
};