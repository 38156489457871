import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';

export const vendorFormUpdate = ({ prop, value }) => {

    return {
        type: 'VENDOR_FORM_UPDATE',
        field: { prop, value }
    };
};

export const vendorFormLoad = (vendor) => {

    return {
        type: 'VENDOR_FORM_LOAD',
        vendor
    };
};

export const vendorFormPurposeUpdate = ({ prop, value }) => {

    return {
        type: 'VENDOR_FORM_PURPOSE_UPDATE',
        field: { prop, value }
    };
};

export const vendorFormError = (message) => {

    return {
        type: 'VENDOR_FORM_ERROR',
        message
    };
};

export const vendorFormSuccess = (message) => {

    return {
        type: 'VENDOR_FORM_SUCCESS',
        message
    };
};

export const vendorFormReset = () => {
    
    return {
        type: 'VENDOR_FORM_RESET',
    };
};

export const submitVendor = (vendor_id, vendor) => {

    return (dispatch) => {

        // dispatch(setVendorLoading());
        return axios.post(API_URL+'/api/vendors', {
            vendorId: vendor_id,
            vendor
        })
        .then(function(res) {
            if (res.data.status == 'success') {
                alert('success!');
                dispatch(vendorFormReset());
            }
            else {
                dispatch(vendorFormError(res.data.message));
            }
        })
        .catch(function(err) {
            
            // token is likely invalid; remove it and prompt the user to login again
            dispatch(vendorFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            
        });
    };
};


export const configureVendorForm = (vendorData) => {

    if (vendorData.vendor_id) {
        return {
            type: 'VENDOR_FORM_CONFIGURE',
            data: vendorData
        }
    }
    else {
        return {
            type: 'VENDOR_FORM_RESET',
        };
    }
}

export const updateVendorData = (id, vendor_id, bookings_date, data) => {

    return (dispatch) => {

        // dispatch(setBookingsLoading());

        // modify the bookings date to the first
        const dateMatch = bookings_date.match(/^(\d\d\d\d)\-(\d\d)\-\d\d$/);
        let bookings_date_first = bookings_date;
        if (dateMatch) {
            bookings_date_first = dateMatch[1]+"-"+dateMatch[2]+"-01";
        }
        

        return axios.post(API_URL+'/api/bookings/'+id+'/vendor/update', {
            bookings_date: bookings_date_first,
            vendor_id,
            data
        }).then(function(res) {
            if (res.data.status == 'success') {

                // @TODO: we need to update this data in redux still
                // dispatch(occupancyFormUpdate({ prop: 'dailySuccess', value: true}));
            }
            else {
                dispatch(vendorFormError(res.data.message));
            }

        }).catch(function(err) {
            // token is likely invalid; remove it and prompt the user to login again
            if (err.response.status === 401) {
                removeAuthToken(dispatch);
            }
            else {
                dispatch(vendorFormError("We're sorry, but there was an issue processing your request. Our support has been notified of the issue."));
            }
            
        });
    };
};