const bookingsFormDefaultState = {
    bookings: [],
    occupants: [],
    loading: false,
    message: '',
    error: false,
    success: false
};

export default (state = bookingsFormDefaultState, action) => {
    switch (action.type) {
        case 'BOOKINGS_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'BOOKINGS_FORM_DAY_UPDATE':
            let theBookings = state.bookings;
            theBookings[action.day] = action.value;

            return {
                ...state,
                bookings: theBookings
            };

        case 'BOOKINGS_FORM_DAY_OCCUPANT_UPDATE':
            let theOccupants = state.occupants;
            theOccupants[action.day] = action.value;

            return {
                ...state,
                occupants: theOccupants
            };

        case 'BOOKINGS_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message,
                success: false
            };

        case 'BOOKINGS_FORM_SUCCESS':
                return {
                    ...state,
                    message: '',
                    error: false,
                    success: true
                }

        case 'BOOKINGS_FORM_RESET':
            return bookingsFormDefaultState;

        default:
            return state;
    }
};