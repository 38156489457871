const selectedAssociationDefaultState = {
    id: 0,
    sitename: '',
    association: {}
};

export default (state = selectedAssociationDefaultState, action) => {
    switch (action.type) {

        case 'SET_SELECTED_ASSOCIATION_ID':
            return {
                ...state,
                id: action.id
            };

        case 'SET_SELECTED_ASSOCIATION':
            return {
                ...state,
                association: action.association
            };

        default:
            return state;
    }
};