import React from "react";
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import { getMonthName } from '../../utils/dateUtil';
import checkbox_unchecked from '../../assets/images/checkbox_empty.png';
import checkbox_checked from '../../assets/images/checkbox_check.png';


const styles = {
  page: {
    flexDirection: 'column',
  },
  pageTitle: {
    marginTop: 20,
    marginBottom: 5,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 900
  },
  pageInstructions: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10
  },
  pageInfoRow: {
      flexDirection: 'row'
  },
  pageInfoColumnLeft: {
      flex: 1,
      marginLeft: 30,
      marginRight: 5,
      flexGrow: 1
  },
  pageInfoColumnRight: {
        flex: 1,
        marginLeft: 5,
        marginRight: 30,
        flexGrow: 1
    },
    nameWindow: {
        borderTopWidth: 1,
        borderTopColor: '#000000',
        borderLeftWidth: 1,
        borderLeftColor: '#000000',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderColor: '#000',
        marginBottom: 10,
    },
    windowTitle: {
        backgroundColor: '#CCCCCC',
        borderBottomWidth: 1,
        borderBottomColor: '#000000'
    },
    windowTitleRow: {
        backgroundColor: '#CCCCCC',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        flexDirection: 'row'
    },
    windowInfo: {
        borderBottomWidth: 1,
        borderBottomColor: '#000000'
    },
    nameWindowTopText: {
        fontSize: 10,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5
    },
    nameWindowBottomText: {
        fontSize: 10,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 5,
        marginTop: 20
    },
    formFontStyle: {
        fontSize: 10,
        marginTop: 5,
        marginLeft: 3,
        marginBottom: 3
    },
    monthWindow: {
        borderTopWidth: 1,
        borderTopColor: '#000000',
        borderLeftWidth: 1,
        borderLeftColor: '#000000',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderColor: '#000',
        marginBottom: 10,
    },
    rentableUnitsWindow: {
        borderTopWidth: 1,
        borderTopColor: '#000000',
        borderLeftWidth: 1,
        borderLeftColor: '#000000',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderColor: '#000',
        marginBottom: 10,
    },
    purposeWindow: {
        borderTopWidth: 1,
        borderTopColor: '#000000',
        borderLeftWidth: 1,
        borderLeftColor: '#000000',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderColor: '#000',
        marginBottom: 10,
    },
    rentableUnitsIntstructions: {
        fontSize: 8,
        margin: 3,
        marginTop: 8
    },
    tourismNSInformation: {
        marginBottom: 10
    },
    informationTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: 3,
    },
    informationText: {
        fontSize: 10,
        marginBottom: 3
    },
    informationLink: {
        textDecoration: 'underline',
        color: '#000099',
        fontSize: 10,
        marginBottom: 3
    },
    signatureWindow: {
        marginTop: 20,
        borderTopWidth: 1,
        borderTopColor: '#000000'
    },
    signatureText: {
        fontSize: 14
    },
    windowMonthRow: {
        flexDirection: 'row'
    },
    windowMonthColLeft: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        paddingTop: 5,
        paddingBottom: 5,
    },
    windowMonthColRight: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        paddingTop: 5,
        paddingBottom: 5,
    },
    monthName: {
        fontSize: 10,
        marginLeft: 5
    },
    image_checkbox: {
        height: 10,
        width: 10
    },
    image_checkbox_closed: {
        height: 30,
        width: 30
    },
    windowPurposeRow: {
        flexDirection: 'row'
    },
    windowPurposeColLeft: {
        flex: 3,
        textAlign: 'left',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        paddingTop: 5,
        paddingBottom: 5,
    },
    windowPurposeColRight: {
        flex: 1,
        textAlign: 'right',
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        paddingTop: 5,
        paddingBottom: 5,
    },
    purposeText: {
        fontSize: 10,
        marginLeft: 5
    },
    purposePercent: {
        fontSize: 10,
        marginRight: 5
    },
    windowBookingsRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
    },
    windowBookingsSection: {
        flex: 2,
        textAlign: 'center',
        borderRightWidth: 1,
        borderRightColor: '#000000',
    },
    windowBookingsSectionLast: {
        flex: 3,
        textAlign: 'center',
    },
    closedWindowText: {
        textAlign: 'center',
        fontSize: 10,
        marginBottom: 20
    },
    closedCheckWindowText: {
        fontSize: 14,
        paddingTop: 7,
        marginRight: 10
    },
    closedCheckWindow: {
        flexDirection: 'row'
    }
};

class VendorAccommodations extends React.Component {

    // shouldComponentUpdate() {
    //     return false;
    // }

    renderDailyBookingsForm() {
        const total_days = new Date(this.props.bookings_date.getFullYear(), this.props.bookings_date.getMonth()+1, 0).getDate();
        let dailyBookingsItems = [];

        for (let i = 1; i <= total_days; i++) {
            const dailyBookingsItem = (
                <View style={styles.windowBookingsRow} key={i}>
                    <View style={styles.windowBookingsSection}>
                        <Text style={styles.formFontStyle}>{i}</Text>
                    </View>
                    <View style={styles.windowBookingsSection}>
                        <Text style={styles.formFontStyle}>{this.props.bookings[i] || ''}</Text>
                    </View>
                    <View style={styles.windowBookingsSectionLast}>
                        <Text style={styles.formFontStyle}>{this.props.occupants[i] || ''}</Text>
                    </View>
                </View>
            );
            dailyBookingsItems.push(dailyBookingsItem);
        }

        return dailyBookingsItems;
    }

    render() {
        return (
            <Document>
                <Page style={styles.page}>
                    <View>
                        <Text style={styles.pageTitle}>Confidential Monthly Occupancy Report</Text>
                        <Text style={styles.pageInstructions}>Please submit completed forms within SEVEN DAYS after the end of each month</Text>
                    </View>
                    <View style={styles.pageInfoRow}>
                        <View style={styles.pageInfoColumnLeft}>

                            <View style={styles.nameWindow}>
                                <View style={styles.windowTitle}>
                                    <Text style={styles.formFontStyle}>PROPERTY NAME AND LICENCE NUMBER</Text>
                                </View>
                                <View style={styles.windowInfo}>
                                    <Text style={styles.nameWindowTopText}>
                                        Property Name: {this.props.vendor.name}
                                    </Text>

                                    <Text style={styles.nameWindowBottomText}>
                                        Licence Number: {this.props.vendor.licence_number}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.monthWindow}>
                                <View style={styles.windowTitle}>
                                    <Text style={styles.formFontStyle}>REPORT FOR THE MONTH OF {getMonthName(this.props.bookings_date.getMonth()).toUpperCase()}, YEAR {this.props.bookings_date.getFullYear()}</Text>
                                </View>
                                <View style={styles.windowMonthRow}>
                                    <View style={styles.windowMonthColLeft}>
                                        {(this.props.bookings_date.getMonth() == 0) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(0).toUpperCase()}
                                        </Text>
                                    </View>
                                    <View style={styles.windowMonthColRight}>
                                    {(this.props.bookings_date.getMonth() == 1) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(1).toUpperCase()}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowMonthRow}>
                                    <View style={styles.windowMonthColLeft}>
                                        {(this.props.bookings_date.getMonth() == 2) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(2).toUpperCase()}
                                        </Text>
                                    </View>
                                    <View style={styles.windowMonthColRight}>
                                    {(this.props.bookings_date.getMonth() == 3) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(3).toUpperCase()}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowMonthRow}>
                                    <View style={styles.windowMonthColLeft}>
                                        {(this.props.bookings_date.getMonth() == 4) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(4).toUpperCase()}
                                        </Text>
                                    </View>
                                    <View style={styles.windowMonthColRight}>
                                    {(this.props.bookings_date.getMonth() == 5) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(5).toUpperCase()}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowMonthRow}>
                                    <View style={styles.windowMonthColLeft}>
                                        {(this.props.bookings_date.getMonth() == 6) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(6).toUpperCase()}
                                        </Text>
                                    </View>
                                    <View style={styles.windowMonthColRight}>
                                    {(this.props.bookings_date.getMonth() == 7) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(7).toUpperCase()}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowMonthRow}>
                                    <View style={styles.windowMonthColLeft}>
                                        {(this.props.bookings_date.getMonth() == 8) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(8).toUpperCase()}
                                        </Text>
                                    </View>
                                    <View style={styles.windowMonthColRight}>
                                    {(this.props.bookings_date.getMonth() == 9) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(9).toUpperCase()}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowMonthRow}>
                                    <View style={styles.windowMonthColLeft}>
                                        {(this.props.bookings_date.getMonth() == 10) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(10).toUpperCase()}
                                        </Text>
                                    </View>
                                    <View style={styles.windowMonthColRight}>
                                    {(this.props.bookings_date.getMonth() == 11) ? (
                                            <Image 
                                                source={checkbox_checked}
                                                style={styles.image_checkbox}
                                            />
                                        ) : (
                                            <Image 
                                                source={checkbox_unchecked}
                                                style={styles.image_checkbox}
                                            />
                                        )} 
                                        <Text style={styles.monthName}>
                                            {getMonthName(11).toUpperCase()}
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.rentableUnitsWindow}>
                                <View style={styles.windowTitle}>
                                    <Text style={styles.formFontStyle}>NUMBER OF RENTABLE UNITS</Text>
                                </View>
                                <View style={styles.windowInfo}>
                                    <Text style={styles.nameWindowTopText}>
                                        # of units available this month: {this.props.total_occupancy}
                                    </Text>

                                    <Text style={styles.rentableUnitsIntstructions}>
                                        Typically, this is the number of units you are licensed to operate. However, if part of an establishment is closed, then these units should not be included as available during this period.
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.purposeWindow}>
                                <View style={styles.windowTitle}>
                                    <Text style={styles.formFontStyle}>TRIP PURPOSE INFORMATION</Text>
                                </View>
                                <View style={styles.windowPurposeRow}>
                                    <View style={styles.windowPurposeColLeft}>
                                        <Text style={styles.purposeText}>
                                            Vacationing
                                        </Text>
                                    </View>
                                    <View style={styles.windowPurposeColRight}>
                                        <Text style={styles.purposePercent}>
                                            {this.props.reportData.trip_purpose.vacationing} %
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowPurposeRow}>
                                    <View style={styles.windowPurposeColLeft}>
                                        <Text style={styles.purposeText}>
                                            Business
                                        </Text>
                                    </View>
                                    <View style={styles.windowPurposeColRight}>
                                        <Text style={styles.purposePercent}>
                                            {this.props.reportData.trip_purpose.business} %
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowPurposeRow}>
                                    <View style={styles.windowPurposeColLeft}>
                                        <Text style={styles.purposeText}>
                                            Convention
                                        </Text>
                                    </View>
                                    <View style={styles.windowPurposeColRight}>
                                        <Text style={styles.purposePercent}>
                                            {this.props.reportData.trip_purpose.convention} %
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowPurposeRow}>
                                    <View style={styles.windowPurposeColLeft}>
                                        <Text style={styles.purposeText}>
                                            Motorcoach
                                        </Text>
                                    </View>
                                    <View style={styles.windowPurposeColRight}>
                                        <Text style={styles.purposePercent}>
                                            {this.props.reportData.trip_purpose.motorcoach} %
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowPurposeRow}>
                                    <View style={styles.windowPurposeColLeft}>
                                        <Text style={styles.purposeText}>
                                            Other
                                        </Text>
                                    </View>
                                    <View style={styles.windowPurposeColRight}>
                                        <Text style={styles.purposePercent}>
                                            {this.props.reportData.trip_purpose.other} %
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.windowPurposeRow}>
                                    <View style={styles.windowPurposeColLeft}>
                                        <Text style={styles.purposeText}>
                                            TOTAL
                                        </Text>
                                    </View>
                                    <View style={styles.windowPurposeColRight}>
                                        <Text style={styles.purposePercent}>
                                            100 %
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.tourismNSInformation}>
                                <Text style={styles.informationTitle}>INTERNET:</Text>
                                <Text style={styles.informationLink}>https://tourismns.ca/monthly-occupancy-fixed-roof</Text>
                            </View>

                            <View style={styles.tourismNSInformation}>
                                <Text style={styles.informationTitle}>MAIL:</Text>
                                <Text style={styles.informationText}>Research Section</Text>
                                <Text style={styles.informationText}>Tourism Nova Scotia</Text>
                                <Text style={styles.informationText}>8 Water Street</Text>
                                <Text style={styles.informationText}>PO Box 667</Text>
                                <Text style={styles.informationText}>Windsor, NS B0N 2T0</Text>
                            </View>

                            <View style={styles.tourismNSInformation}>
                                <Text style={styles.informationTitle}>FAX:</Text>
                                <Text style={styles.informationText}>(902) 798-6600</Text>
                            </View>

                            <View style={styles.tourismNSInformation}>
                                <Text style={styles.informationTitle}>For more information:</Text>
                                <Text style={styles.informationText}>Email: occupancystats@novascotia.ca</Text>
                                <Text style={styles.informationText}>Phone: (902) 798-7633</Text>
                            </View>

                            <View style={styles.signatureWindow}>
                                <Text style={styles.signatureText}>Signature of Authorized Person</Text>
                            </View>
                        </View>
                        <View style={styles.pageInfoColumnRight}>
                            <View style={styles.purposeWindow}>
                                <View style={styles.windowTitleRow}>
                                    <View style={styles.windowBookingsSection}>
                                        <Text style={styles.formFontStyle}>DAY</Text>
                                    </View>
                                    <View style={styles.windowBookingsSection}>
                                        <Text style={styles.formFontStyle}>UNITS SOLD</Text>
                                    </View>
                                    <View style={styles.windowBookingsSectionLast}>
                                        <Text style={styles.formFontStyle}>NO. OF GUESTS</Text>
                                    </View>
                                </View>
                                {this.renderDailyBookingsForm()}
                            </View>
                            <View style={styles.closedWindow}>
                               <Text style={styles.closedWindowText}>If the accommodation is a seasonal operation, please check on final return.</Text>

                               <View style={styles.closedCheckWindow}>
                                    <Text style={styles.closedCheckWindowText}>
                                        CLOSED FOR THE SEASON
                                    </Text>
                                    <Image 
                                        source={(this.props.reportData.closed_for_season) ? checkbox_checked : checkbox_unchecked}
                                        style={styles.image_checkbox_closed}
                                    />
                               </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}

export default VendorAccommodations;
