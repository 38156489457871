import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateSubmittedReportPdf = (data) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  console.log(data);

  // initialize jsPDF
  const doc = new jsPDF();

  const date = Date().split(" ");
  let today = new Date;
  let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')
  // we use a date string to generate our filename.
  const dateStr = date[2] + "_" + monthNum + "_" + date[3];
  const generatedDate = monthNames[today.getMonth() + 1] + " " + date[2] + ", " + date[3];

    doc.text("Monthly Marketing Levy Report", 14, 14);

    doc.setFontSize(14);
    doc.text("Reporting Period: "+data.date, 14, 27);

    doc.text("Registration ID: "+data.vendor.licence_number, 14, 35);

    doc.text("Address: "+data.vendor.address, 14, 43);

    doc.text("Generated on: "+generatedDate, 14, 51);

    doc.text("Total Rooms Rented:", 14, 68);
    doc.text(String(data.rooms_rented), 170, 68);
    doc.text("Total Room Revenue for Period:", 14, 78);
    doc.text("$"+parseFloat(data.total_room_revenue).toFixed(2), 170, 78);
    doc.setTextColor(150, 0, 0);
    doc.text("(Deduct): Adjustments for Bad Debts and Refund due to Errors:", 14, 88);
    doc.text("-$"+parseFloat(data.adjustment_refunds).toFixed(2), 168, 88);
    doc.setTextColor(0, 0, 0);
    doc.text("Add: Adjustments to Revenue:", 14, 98);
    doc.text("$"+parseFloat(data.adjustment_revenue).toFixed(2), 170, 98);
    doc.text("Adjusted Room Revenue subject to Levy in Reporting Period:", 14, 108);
    doc.text("$"+parseFloat(data.revenue_subject_to_levy).toFixed(2), 170, 108);
    doc.text("Total Marketing Levy Due:", 14, 118);  
    doc.text("$"+parseFloat(data.total_due).toFixed(2), 170, 118);  
  
  doc.save(`submitted_report_${dateStr}.pdf`);

};

export default generateSubmittedReportPdf;