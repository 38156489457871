import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateLevyReportedPdf = (reportData, inputData) => {
  // initialize jsPDF
  const doc = new jsPDF();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const tableColumn = ["Levies Reported", ""];   
  const tableRows = [
    [
        "Total Room Revenue for Period:",
        '$' + reportData.total_room_revenue.toFixed(2)
    ],
    [
        "(Deduct): Adjustments for Bad Debts and Refund due to Errors",
        '-$' + reportData.adjustment_refunds.toFixed(2)
    ],
    [
        "Add: Adjustments to Revenue:",
        '$' + reportData.adjustment_revenue.toFixed(2)
    ],
    [
        "Adjusted Room Revenue subject to Levy in Reporting Period:",
        '$' + reportData.revenue_subject_to_levy.toFixed(2)
    ],
    [
        "Total Marketing Levy Due (3%):",
        '$' + reportData.total_due.toFixed(2)
    ]
  ];

  const date = Date().split(" ");
  let today = new Date;
  let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')
  // we use a date string to generate our filename.
  const dateStr = date[2] + "_" + monthNum + "_" + date[3];
  const generatedDate = monthNames[today.getMonth() + 1] + " " + date[2] + ", " + date[3];


  doc.text("Levy Totals Report", 80, 14);

    doc.setFontSize(14);

    doc.text(monthNames[inputData.start_month - 1] + " " + inputData.start_year + " - " + monthNames[inputData.end_month - 1] + " " + inputData.end_year, 79, 22);

    doc.setFontSize(12);
    doc.text("Generated on: "+generatedDate, 76, 28);


    doc.setFontSize(18);
  doc.text("Accommodations Reporting:", 65, 39);

  doc.setFontSize(30);
  doc.setTextColor(56, 182, 255);
  doc.text(reportData.accommodations_reporting.toFixed(2) + '%', 90, 52).setFont(undefined, 'bold');

  doc.autoTable(tableColumn, tableRows, { startY: 65 });

  
  doc.save(`report_levyReported_${dateStr}.pdf`);

};

export default generateLevyReportedPdf;