import React from 'react';
import moment from 'moment';
import { Bar, Polar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import verifyLogin from '../utils/verifyLogin';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import { setSelectedAssociationID } from '../actions/selectedAssociation';
import { getAnalyticsInfo } from '../actions/analytics';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import RoomBookingsInformation from './RoomBookingsInformation';

class AnalyticsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            initLoad: false,
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getAnalyticsInfo(this.props.match.params.id, this.props.match.params.vid || 0));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    render() {
        setBackgroundPhoto('');
        const barData = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
              {
                label: 'First',
                backgroundColor: '#38b6ff',
                borderColor: '#38b6ff',
                borderWidth: 1,
                hoverBackgroundColor: '#38b6ff',
                hoverBorderColor: '#38b6ff',
                data: [65, 59, 80, 81, 56, 55, 92, 65, 59, 80, 81, 56],
              },
              {
                label: 'Second',
                backgroundColor: '#134866',
                borderColor: '#134866',
                borderWidth: 1,
                hoverBackgroundColor: '#134866',
                hoverBorderColor: '#134866',
                data: [65, 59, 65, 59, 80, 81, 56, 80, 81, 56, 55, 92],
              }
            ]
        };

        const polarData = {
            datasets: [{
              data: [
                11,
                16,
                7,
                14
              ],
              backgroundColor: [
                '#FF6384',
                '#4BC0C0',
                '#FFCE56',
                '#36A2EB'
              ],
              label: 'County' // for legend
            }],
            labels: [
                'Cape Breton County',
                'Richmond County',
                'Victoria County',
                'Inverness County'
            ]
        };  
        
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className="daily-bookings-header-window">
                        <h1 className="daily-bookings-title">Room Bookings - Analytics</h1>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <RoomBookingsInformation 
                        title={"Last Month"}
                        total={this.props.totals.last_month.total}
                        change={this.props.totals.last_month.change}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <RoomBookingsInformation 
                        title={"This Month"}
                        total={this.props.totals.this_month.total}
                        change={this.props.totals.this_month.change}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <RoomBookingsInformation 
                        title={"Next Month"}
                        total={this.props.totals.next_month.total}
                        change={this.props.totals.next_month.change}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className="room-bookings-dashboard-title">Comparisons</h2>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <Bar data={barData} />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Polar data={polarData} options={{legend: { display: false }}} />
                </GridItem>
            </GridContainer>
        )
    }
};

const mapStateToProps = (state, props) => {
    return {
        loggedUser: state.auth.user,
        loggedRole: state.auth.role,
        association_id: state.selectedAssociation.id,
        isAuthenticated: state.auth.isAuthenticated,
        sitename: state.selectedAssociation.id,
        totals: state.analytics.totals,
        loading: state.analytics.loading,
        error: state.analytics.error,
        success: state.analytics.success,
    };
};

export default connect(mapStateToProps)(AnalyticsPage);