import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateMunicipalityPDF = () => {
  // initialize jsPDF
  const doc = new jsPDF();

  // doc.text("Bam!!", 10, 10);

  // define the columns we want and their titles
  const tableColumn = ["Municipality Levee Report - "];
  // define an empty array of rows
  const tableRows = [];
  doc.autoTable(tableColumn, tableRows, { startY: 20 });



  /*let commasRegex = /\B(?=(\d{3})+(?!\d))/g;

  if(isDispatch === false){
      // define the columns we want and their titles
      const tableColumn = ["Date", "Order #", "Net Subtotal", "Taxes", "Delivery Fees", "Total"];
      // define an empty array of rows
      const tableRows = [];

      // for each ticket pass all its data into an array
      orders.forEach(order => {
        const orderData = [
            order.time_received_formatted,
            order.order_id,
            '$' + (+order.subtotal).toFixed(2),
            '$' + (+order.taxes).toFixed(2),
            '$' + (+order.delivery_charge + +order.delivery_charge_tax + +order.tip_amount).toFixed(2),
            '$' + (+order.total).toFixed(2),
        ];
        // push each tickcet's info into a row
        tableRows.push(orderData);
      });

      doc.text("Orders - " + summaryTitle, 14, 15);

      // startY is basically margin-top
      doc.autoTable(tableColumn, tableRows, { startY: 20 });

      doc.addPage();
  }

  doc.text("Order Summary - " + summaryTitle, 14, 15);

  // define an empty array of rows
  const tableRowsSumm = [];
  let summaryData = [];
  let lessArray = [];

  if(isDispatch === false){
      // Standard Display
      summaryData = [
          ['Orders Created', summary.totalCreated],
          ['Start Time', summary.summaryStartFormatted],
          ['End Time', summary.summaryEndFormatted],
          ['Orders Completed', summary.totalCompleted],
          ['Orders Cancelled', summary.totalCancelled],
          ['Paid Online', summary.totalPaidOnline],
          ['Paid In store', summary.totalPaidInStore],
          ['Food Revenue', '$' + (((summary.totalSubtotal) && summary.totalSubtotal.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      ];

      lessArray.push(summaryData.length);
    
      // Negative Display
      summaryData.push(
          ['Less - Redeemed Loyalty Rewards', '$-' + (((summary.totalLoyaltyAmount) && summary.totalLoyaltyAmount.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Less - Promo Codes', '$-' + (((summary.totalPromoCodes) && summary.totalPromoCodes.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      );

      for(let i=lessArray[0]+1; i<summaryData.length; i++){
        lessArray.push(i);
      }

      // Standard Display
      summaryData.push(
          ['Net Food Revenue', '$' + (((summary.netFoodRevenue) && summary.netFoodRevenue.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Additional Charges', '$' + (((summary.additionalCharges) && summary.additionalCharges.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Taxes Collected', '$' + (((summary.taxes) && summary.taxes.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Total Restaurant', '$' + (((summary.totalRestaurant) && summary.totalRestaurant.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      );

      if(summary.dispatchAssociated == true){
          summaryData.push(
              ['Delivery Fees Collected', '$' + (((summary.totalDeliveryCharges) && (+summary.totalDeliveryCharges + +summary.deliveryChargeTax + +summary.totalDeliveryTip).toFixed(2).replace(commasRegex, ",")) ?? '0.00')]
          );
      }else if(summary.dispatchAssociated == false){
          summaryData.push(
              ['Delivery Fees Collected', '$' + (((summary.totalDeliveryCharges) && (+summary.totalDeliveryCharges + +summary.deliveryChargeTax + +summary.totalDeliveryTip).toFixed(2).replace(commasRegex, ",")) ?? '0.00')]
          );
          summaryData.push(
              ['Delivery Tips Collected', '$' + (((summary.totalDeliveryTip) && summary.totalDeliveryTip.toFixed(2).replace(commasRegex, ",")) ?? '0.00')]
          );
      }else if(summary.source == 'support'){
        summaryData.push(
            ['Dispatch Delivery Fees Collected', '$' + (((summary.dispatchDeliveryCharges) && summary.dispatchDeliveryCharges.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Dispatch Delivery Tax Collected', '$' + (((summary.dispatchDeliveryChargeTax) && summary.dispatchDeliveryChargeTax.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Dispatch Delivery Tips Collected', '$' + (((summary.dispatchDeliveryTips) && summary.dispatchDeliveryTips.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Restaurant Delivery Fees Collected', '$' + (((summary.restaurantDeliveryCharges) && summary.restaurantDeliveryCharges.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Restaurant Delivery Tax Collected', '$' + (((summary.restaurantDeliveryChargeTax) && summary.restaurantDeliveryChargeTax.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Restaurant Delivery Tips Collected', '$' + (((summary.restaurantDeliveryTips) && summary.restaurantDeliveryTips.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
        );
      }

      if((summary.source ?? false) == 'support'){
        // Standard Display
        summaryData.push(
            ['Fuel Surcharge', '$' + (((summary.fuelSurcharge) && (+summary.fuelSurcharge).toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Fuel Surcharge Tax', '$' + (((summary.fuelSurchargeTax) && (+summary.fuelSurchargeTax).toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Total Collected', '$' + (((summary.totalRevenue) && summary.totalRevenue.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
        );
      }else{
        // Standard Display
        summaryData.push(
            ['Fuel Surcharge (Incl. Tax)', '$' + (((summary.fuelSurcharge) && (+summary.fuelSurcharge + +summary.fuelSurchargeTax).toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
            ['Total Collected', '$' + (((summary.totalRevenue) && summary.totalRevenue.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
        );
      }

      lessArray.push(summaryData.length);
      
      // Negative Display
      summaryData.push(
          ['Delivery Fees Disbursed', '$-' + (((summary.deliveryFeesDisbursed) && summary.deliveryFeesDisbursed.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Click2Order Platform Fee', '$-' + (((summary.platformFees) && Math.abs(summary.platformFees).toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Internal Charge', '$-' + (((summary.internalCharge) && summary.internalCharge.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Taxes Charged to Restaurants on Fees', '$-' + (((summary.platformFeeTax) && summary.platformFeeTax.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
          ['Stripe Processing Fee', '$-' + (((summary.apiCharge) && summary.apiCharge.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      );

      for(let i=lessArray[lessArray.length-1]+1; i<summaryData.length; i++){
        lessArray.push(i);
      }

      // Standard Display
      summaryData.push(
        ['Net Deposit', '$' + (((summary.netRevenue) && summary.netRevenue.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      );
  }else{
      // for each ticket pass all its data into an array
      summaryData = [
        ['Orders Created', summary.totalCreated],
        ['Start Time', summary.summaryStartFormatted],
        ['End Time', summary.summaryEndFormatted],
        ['Orders Completed', summary.totalCompleted],
        ['Orders Cancelled', summary.totalCancelled],
        ['Paid Online', summary.totalPaidOnline],
        ['Paid In store', summary.totalPaidInStore],
    ];

    summaryData.push(
      ['Delivery Fees Collected', '$' + (((summary.totalDeliveryCharges) && summary.totalDeliveryCharges.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      ['Delivery Tips Collected', '$' + (((summary.totalDeliveryTip) && summary.totalDeliveryTip.toFixed(2).replace(commasRegex, ",")) ?? '0.00')],
      // ['Fuel Surcharge (pre-tax)', '$' + (((summary.fuelSurcharge) && summary.fuelSurcharge.toFixed(2).replace(commasRegex, ",")) ?? '0.00')]
    );
    
  }
  

  // push each tickcet's info into a row
  tableRowsSumm.push(...summaryData);

  // startY is basically margin-top
  doc.autoTable({
    theme: 'striped',
    body: tableRowsSumm,          
    startY: 20,
    didParseCell(data) {
      if(isDispatch){
        return;
      }
      let fillColor = (data.row.index % 2 == 0) ? '#FF7783': '#FF5566';

      if (lessArray.includes(data.row.index)) {
        data.cell.styles.textColor = [255, 255, 255];
        data.cell.styles.fillColor = fillColor;
      }
    }
  });*/

  const date = Date().split(" ");
  let today = new Date;
  let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')
  // we use a date string to generate our filename.
  const dateStr = date[2] + "_" + monthNum + "_" + date[3];
  
  doc.save(`report_region_${dateStr}.pdf`);
  // doc.output('dataurlnewwindow'); //to check pdf generate

};

export default generateMunicipalityPDF;