import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { occupancyFormUpdate } from './occupancyForm';
import { setVendorsList } from './vendors';

export const setDashboard = (totals, alerts = [], occupancy = [], vendors = [], chartData = [], feesReportedYTD = 0.00, pastDueFees = {}, pastDueFeesYTD = 0, cityFeesReported = [], vendor_id = 0) => ({
    type: 'DASHBOARD_INFO_UPDATE',
    alerts,
    vendors,
    totals,
    occupancy,
    chartData,
    feesReportedYTD,
    pastDueFees,
    pastDueFeesYTD,
    cityFeesReported,
    vendor_id,
});

export const setDashboardAlerts = (alerts) => ({
    type: 'SET_DASHBOARD_ALERTS',
    alerts,
});

export const setDashboardVendor = (vendor) => ({
    type: 'DASHBOARD_VENDOR_UPDATE',
    vendor,
});

export const setDashboardLoading = () => ({
    type: 'SET_DASHBOARD_LOADING'
});

export const getDashboardInfo = (vendor) => {

    return (dispatch) => {

        dispatch(setDashboardLoading());
        let dashUrl = API_URL+'/api/dashboard';
        if (vendor > 0) {
            dashUrl = API_URL+'/api/dashboard?vendor_id='+vendor;
        }
        return axios.get(dashUrl)
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setDashboard(res.data.totals, res.data.alerts, res.data.occupancy, res.data.vendors, res.data.chart_data, res.data.fees_reported_ytd, res.data.past_due_fees, res.data.past_due_fees_ytd, res.data.city_fees_reported, res.data.vendor_id));
                    dispatch(setDashboardVendor(res.data.vendor));
                    dispatch(setVendorsList(res.data.vendors));
                    dispatch(occupancyFormUpdate({ prop: 'vendor_id', value: res.data.vendor_id }));

                    //  set the initial room bookings number (if applicable)
                    if (res.data.occupancy[res.data.vendor_id]) {
                        dispatch(occupancyFormUpdate({ prop: 'total', value: res.data.occupancy[res.data.vendor_id].total_rooms }));
                        dispatch(occupancyFormUpdate({ prop: 'total_occupants', value: res.data.occupancy[res.data.vendor_id].total_occupants }));
                    }
                }
                else {
                    console.log(res.data);
                    console.log("Could not load dashboard!");
                }
            })
            .catch(function(err) {
                console.log(err);
                
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};