import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import { getAssociations, addAssociation, updateAssociation, removeAssociation } from '../actions/associations';
import { associationFormUpdate, associationFormError, associationFormReset, associationFormLoad } from '../actions/associationForm';
import { openAssociationsCreate, closeAssociationsCreate, openAssociationsUpdate, closeAssociationsUpdate } from '../actions/modals';
import { setSelectedAssociationID } from '../actions/selectedAssociation';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class AssociationsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitCreateAssociation = this.submitCreateAssociation.bind(this);
        this.submitUpdateAssociation = this.submitUpdateAssociation.bind(this);
        this.removeAssociation = this.removeAssociation.bind(this);

        this.state = {
            selectedAssociation: {}
        };
    }

    removeAssociation({ id }) {
        if (confirm("Are you sure you want to remove this association? This cannot be undone")) {
            this.props.dispatch(removeAssociation(id, this.props.match.params.id));
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getAssociations());
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(associationFormReset());
            this.props.dispatch(openAssociationsCreate());
        }
        else {
            this.props.dispatch(closeAssociationsCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedAssociation) {
        if (isOpen) {
            this.setState(() => ({
                selectedAssociation
            }));
            this.props.dispatch(associationFormReset());
            this.props.dispatch(associationFormLoad(selectedAssociation));
            this.props.dispatch(openAssociationsUpdate());
        }
        else {
            this.props.dispatch(closeAssociationsUpdate());
        }
    }

    submitCreateAssociation() {

        // perform some error checking
        if (this.props.associationForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(associationFormError("Please enter a association name."));
        }
        else if (this.props.associationForm.sitename.trim() == "") {

            // required fields aren't present
            this.props.dispatch(associationFormError("Please enter a sitename."));
        }
        else {

            // add the association
            this.props.dispatch(addAssociation(this.props.associationForm));
        }
    }

    submitUpdateAssociation() {

        // perform some error checking
        if (this.props.associationForm.name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(associationFormError("Please enter a association name."));
        }
        else if (this.props.associationForm.sitename.trim() == "") {

            // required fields aren't present
            this.props.dispatch(associationFormError("Please enter a sitename."));
        }
        else {

            // add the committee association
            this.props.dispatch(updateAssociation(this.state.selectedAssociation.id, this.props.associationForm));
        }
    }

    renderCreateModal() {
        const { classes } = this.props;

        return (
            <Modal
                aria-labelledby="association-association-add"
                aria-describedby="association-association-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Add a Association
                    </Typography>
                    <Typography className="modal-description">
                        Please add all required information.
                    </Typography>

                    { this.props.associationForm.error && (
                        <Typography className="modal-error">
                            { this.props.associationForm.error }
                        </Typography>
                    )}

                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.associationForm.name}
                        onChange={(e) => { this.props.dispatch(associationFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Sitename"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.associationForm.sitename}
                        onChange={(e) => { this.props.dispatch(associationFormUpdate({ prop: 'sitename', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.associationForm.phone}
                        onChange={(e) => { this.props.dispatch(associationFormUpdate({ prop: 'phone', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitCreateAssociation}>
                        Add Association
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Update {this.state.selectedAssociation.name}
                    </Typography>
                    <Typography className="modal-description">
                        Please update any required information.
                    </Typography>

                    { this.props.associationForm.error && (
                        <Typography className="modal-error">
                            { this.props.associationForm.error }
                        </Typography>
                    )}

                    { this.props.associationForm.message && (
                        <Typography className="modal-success">
                            { this.props.associationForm.message }
                        </Typography>
                    )}

                    <TextField
                        label="Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.associationForm.name}
                        onChange={(e) => { this.props.dispatch(associationFormUpdate({ prop: 'name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Phone"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.associationForm.phone}
                        onChange={(e) => { this.props.dispatch(associationFormUpdate({ prop: 'phone', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateAssociation}>
                        Update Association
                    </Button>
                </div>
            </Modal>
        )
    }

    renderAssociationsList() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>CheckIn Analytics</h4>
                            <p className={classes.cardCategoryWhite}>
                            Associations
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHead={["Name", "Sitename", "Phone"]}
                                tableData={this.props.associations.map((association) => [
                                    association.name, 
                                    association.sitename, 
                                    association.phone,
                                    (
                                        <div>
                                            {(association.role != "owner") && <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, association)} />}
                                            {(association.role != "owner") && <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeAssociation(association)} />}
                                        </div>
                                    )
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderAssociationsList()}

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.associations.loading,
        associations: state.associations.associations,
        associationForm: state.associationForm,
        createModalOpen: state.modals.associationsCreate,
        updateModalOpen: state.modals.associationsUpdate,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(AssociationsPage));
