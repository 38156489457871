import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateFeesTablePdf = (reportData, inputData) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles
  const tableColumn = ["Company", "Owner", "Email", "0 - 30 Days", "31 - 60 Days", "61 - 90 Days", "90+ Days", "Total"];   

  // define an empty array of rows
  const tableRows = [];

  reportData.forEach(report => {
    const row = [
        report.company, 
        report.owner,
        report.email,
        report['0-30'].toFixed(2),
        report['31-60'].toFixed(2),
        report['61-90'].toFixed(2),
        report['90+'].toFixed(2),
        report.total.toFixed(2)
    ];
    // push each tickcet's info into a row
    tableRows.push(row);
  });

  const date = Date().split(" ");
  let today = new Date;
  let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')
  // we use a date string to generate our filename.
  const dateStr = date[2] + "_" + monthNum + "_" + date[3];
  const generatedDate = monthNames[today.getMonth() + 1] + " " + date[2] + ", " + date[3];
  

    doc.text("Fees Past Due Report", 75, 14);

    doc.setFontSize(14);

    doc.text(monthNames[inputData.start_month - 1] + " " + inputData.start_year + " - " + monthNames[inputData.end_month - 1] + " " + inputData.end_year, 79, 22);

    doc.setFontSize(12);
    doc.text("Generated on: "+generatedDate, 76, 28);



    doc.autoTable(tableColumn, tableRows, { startY: 36 });


  
  
  doc.save(`report_feesPastDue_${dateStr}.pdf`);

};

export default generateFeesTablePdf;