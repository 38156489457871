import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts an orders argument
const generateVendorReportPdf = (vendor, data) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  console.log("The Info");
  console.log(vendor);
  console.log(data);

  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles
  const tableColumn = ["Report Date", "Status", "Reported Total Due"];   

  // define an empty array of rows
  const tableRows = [];

  data.reports.forEach(report => {
    const row = [
        report.date, 
        report.status,
        report.total_due
    ];
    // push each tickcet's info into a row
    tableRows.push(row);
  });

  const date = Date().split(" ");
  let today = new Date;
  let monthNum = (today.getMonth() + 1).toString().padStart(2, '0')
  // we use a date string to generate our filename.
  const dateStr = date[2] + "_" + monthNum + "_" + date[3];
  const generatedDate = monthNames[today.getMonth() + 1] + " " + date[2] + ", " + date[3];
  

    doc.text("Fees Past Due Report", 75, 14);

    doc.text(vendor.address, 79, 22);

    doc.setFontSize(12);
    doc.text("Generated on: "+generatedDate, 76, 28);

    doc.setFontSize(16);
    doc.text("Total Room Revenue for Period: $"+data.total_room_revenue.toFixed(2), 14, 38);
    doc.setTextColor(150, 0, 0);
    doc.text("(Deduct): Adjustments for Bad Debts and Refund due to Errors: -$"+data.adjustment_refunds.toFixed(2), 14, 48);
    doc.setTextColor(0, 0, 0);
    doc.text("Add: Adjustments to Revenue: $"+data.adjustment_revenue.toFixed(2), 14, 58);
    doc.text("Adjusted Room Revenue subject to Levy in Reporting Period: $"+data.revenue_subject_to_levy.toFixed(2), 14, 68);
    doc.text("Total Marketing Levy Due: $"+data.total_due.toFixed(2), 14, 78);



    doc.autoTable(tableColumn, tableRows, { startY: 85 });


  
  
  doc.save(`report_feesPastDue_${dateStr}.pdf`);

};

export default generateVendorReportPdf;