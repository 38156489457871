import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { updateProfile } from '../../actions/auth';
import { profileFormUpdate, profileFormError, profileFormReset, profileFormLoad } from '../../actions/profileForm';
import { openProfileUpdate, closeProfileUpdate } from '../../actions/modals';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class UpdateProfile extends React.Component {

    constructor(props) {
        super(props);

        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.submitUpdateProfile = this.submitUpdateProfile.bind(this);
    }

    toggleUpdateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(profileFormReset());
            this.props.dispatch(profileFormLoad([
                this.props.user.first_name,
                this.props.user.last_name
            ]));
            this.props.dispatch(openProfileUpdate());
        }
        else {
            this.props.dispatch(closeProfileUpdate());
        }
    }

    submitUpdateProfile() {

        // perform some error checking
        if (this.props.profileForm.password.trim() != "" && this.props.profileForm.password != this.props.profileForm.password_confirm) {

            // required fields aren't present
            this.props.dispatch(profileFormError("Passwords do not match."));
        }
        else {

            // add the committee profile
            this.props.dispatch(updateProfile(this.props.profileForm));
        }
    }
    
    render() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.modalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Update Profile
                    </Typography>
                    <Typography className="modal-description">
                        Please update any information as necessary, and click 'Update Profile'.
                    </Typography>

                    { this.props.profileForm.error && (
                        <Typography className="modal-error">
                            { this.props.profileForm.error }
                        </Typography>
                    )}

                    { this.props.profileForm.message && (
                        <Typography className="modal-success">
                            { this.props.profileForm.message }
                        </Typography>
                    )}

                    <TextField
                        label="First Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.profileForm.first_name}
                        onChange={(e) => { this.props.dispatch(profileFormUpdate({ prop: 'first_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Last Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.profileForm.last_name}
                        onChange={(e) => { this.props.dispatch(profileFormUpdate({ prop: 'last_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Password"
                        type="password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.profileForm.password}
                        onChange={(e) => { this.props.dispatch(profileFormUpdate({ prop: 'password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        label="Confirm Password"
                        type="password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.profileForm.password_confirm}
                        onChange={(e) => { this.props.dispatch(profileFormUpdate({ prop: 'password_confirm', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitUpdateProfile}>
                        Update Profile
                    </Button>
                </div>
            </Modal>
        )
    }
};

const mapStateToProps = (state) => {

    return {
        loggedRole: state.auth.role,
        loading: state.profileForm.loading,
        profileForm: state.profileForm,
        modalOpen: state.modals.profileUpdate,
        user: state.auth.user
    };
};

export default connect(mapStateToProps)(withStyles(styles)(UpdateProfile));
