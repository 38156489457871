const loginFormDefaultState = {
    emailAddress: '',
    password: '',
    rememberMe: false,
    message: '',
    error: '',
};

export default (state = loginFormDefaultState, action) => {
    switch (action.type) {
        case 'LOGIN_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'LOGIN_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'LOGIN_FORM_RESET':
            return loginFormDefaultState;

        default:
            return state;
    }
};