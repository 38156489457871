export const occupancyFormUpdate = ({ prop, value }) => {

    return {
        type: 'OCCUPANCY_FORM_UPDATE',
        field: { prop, value }
    };
};

export const occupancyFormError = (message) => {

    return {
        type: 'OCCUPANCY_FORM_ERROR',
        message
    };
};

export const occupancyFormSuccess = (message) => {

    return {
        type: 'OCCUPANCY_FORM_SUCCESS',
        message
    };
};

export const occupancyFormReset = () => {
    
    return {
        type: 'OCCUPANCY_FORM_RESET',
    };
}