import { getCurrentTime } from '../utils/dateUtil';

const occupancyFormDefaultState = {
    total: 0,
    total_occupants: 0,
    vendor_id: 0,
    bookings_date: getCurrentTime(true),
    loading: false,
    message: '',
    dailySuccess: false,
    error: '',
};

export default (state = occupancyFormDefaultState, action) => {
    switch (action.type) {
        case 'OCCUPANCY_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'OCCUPANCY_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'OCCUPANCY_FORM_RESET':
            return occupancyFormDefaultState;

        default:
            return state;
    }
};