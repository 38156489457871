const analyticsDefaultState = {
    totals: {
        last_month: {
            total: 0,
            change: 0.00
        },
        this_month: {
            total: 0,
            change: 0.00
        },
        next_month: {
            total: 0,
            change: 0.00
        }
    },
    loading: true,
    message: '',
    error: '',
};

export default (state = analyticsDefaultState, action) => {
    switch (action.type) {
        case 'ANALYTICS_INFO_UPDATE':
            return {
                ...state,
                totals: action.totals,
                loading: false,
            };

        case 'SET_ANALYTICS_LOADING':
            return {
                ...state,
                loading: true
            }

        case 'ANALYTICS_ERROR':
            return {
                ...state,
                message: '',
                error: action.message,
                loading: false
            }

        case 'ANALYTICS_RESET':
            return analyticsDefaultState;

        default:
            return state;
    }
};