const userFormDefaultState = {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    password: '',
    confirm_password: '',
    vendor_id: 0,
    municipality_id: 0,
    association_id: 0,
    search: '',
    error: '',
};

export default (state = userFormDefaultState, action) => {
    
    switch (action.type) {

        case 'USER_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'USER_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'USER_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'USER_FORM_RESET':
            return userFormDefaultState;

        default:
            return state;
    }
};