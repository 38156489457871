import React from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { loginFormUpdate, loginFormError } from '../actions/loginForm';
import { login } from '../actions/auth';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { getQueryParameters } from '../utils/routeUtil';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);   

        this.onSubmitLogin = this.onSubmitLogin.bind(this);

        this.state = {
            redirect: null
        }
    }

    componentDidMount() {

        const queryParams = getQueryParameters(this.props.location.search);
        if (queryParams.redirect) {
            this.setState(() => ({redirect: queryParams.redirect}));
        }

        if (localStorage.getItem('checkinToken')) {
            this.props.history.push('/');
        }
    }


    onSubmitLogin(e) {
        e.preventDefault();

        if (!this.props.emailAddress || !this.props.password) {
            this.props.dispatch(loginFormError('Please enter all login info before proceeding.'));
        }
        else {
            this.props.dispatch(login(this.props.emailAddress, this.props.password, this.props.rememberMe, this.props.history));
        }
    }

    render() {
        setBackgroundPhoto('login');

        return (
            <div className="login-form">
                <Paper className="paper">
                    <Avatar className="avatar">
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    { this.props.error && <p className="text-center text-error">{this.props.error}</p> }
                    <form className="form" onSubmit={this.onSubmitLogin}>
                        {(this.state.redirect) && <input type="hidden" name="redirect" value={this.state.redirect} />}
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Address</InputLabel>
                            <Input id="email" name="email" autoComplete="email" value={this.props.emailAddress} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'emailAddress', value: e.target.value }))} autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password" value={this.props.password} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'password', value: e.target.value }))} />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="remember" checked={this.props.rememberMe} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'rememberMe', value: !this.props.rememberMe }))} color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="submit submit-prim"
                        >
                            Sign in
                        </Button>
                    </form>

                    {/* <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className="submit submit-sec"
                    >
                        Forgot Your Password?
                    </Button> */}
                    <a href="https://checkinanalytics.webflow.io/" target="_blank">
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="submit submit-sec"
                        >
                            Register Your Accommodation
                        </Button>
                    </a>
                </Paper>
            </div>

            // <div className="container login-container">
            //     <div className="login-form">
            //         <h1>Sign in to your Account</h1>
            //         <hr />
            //         { this.props.error && <p className="text-center text-error">{this.props.error}</p> }
            //         <form onSubmit={this.onSubmitLogin}>
            //             <div className="input-group input-group-lg mb-3">
            //                 <input type="text" className="form-control" placeholder="Email Address" value={this.props.emailAddress} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'emailAddress', value: e.target.value }))} autoFocus />
            //             </div>

            //             <div className="input-group input-group-lg mb-3">
            //                 <input type="password" className="form-control" placeholder="Password" value={this.props.password} onChange={(e) => this.props.dispatch(loginFormUpdate({ prop: 'password', value: e.target.value }))} />
            //             </div>

            //             <div className="form-group">
            //                 <button className="btn btn-lg btn-block button">Sign in</button>
            //             </div>
            //         </form>
            //         <p className="text-center">
            //             <a href="/password/forgot">Forgot your password?</a>
            //         </p>
            //     </div>
            // </div>
        );
    }

};

const mapStateToProps = (state, props) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        emailAddress: state.loginForm.emailAddress,
        password: state.loginForm.password,
        rememberMe: state.loginForm.rememberMe,
        error: state.loginForm.error
    };
};

export default connect(mapStateToProps)(LoginPage);