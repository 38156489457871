const associationFormDefaultState = {
    name: '',
    sitename: '',
    phone: '',
    message: '',
    error: '',
};

export default (state = associationFormDefaultState, action) => {
    
    switch (action.type) {

        case 'ASSOCIATION_FORM_UPDATE':
            return {
                ...state,
                [action.field.prop]: action.field.value
            };

        case 'ASSOCIATION_FORM_LOAD':
            return {
                ...state,
                ...action.association
            };

        case 'ASSOCIATION_FORM_ERROR':
            return {
                ...state,
                message: '',
                error: action.message
            }

        case 'ASSOCIATION_FORM_SUCCESS':
            return {
                ...state,
                error: '',
                message: action.message
            }

        case 'ASSOCIATION_FORM_RESET':
            return associationFormDefaultState;

        default:
            return state;
    }
};