import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import loginFormReducer from '../reducers/loginForm';
import sidebarReducer from '../reducers/sidebar';
import modalsReducer from '../reducers/modals';
import selectedAssociationReducer from '../reducers/selectedAssociation';
import usersReducer from '../reducers/users';
import userFormReducer from '../reducers/userForm';
import occupancyFormReducer from '../reducers/occupancyForm';
import dashboardReducer from '../reducers/dashboard';
import bookingsReducer from '../reducers/bookings';
import bookingsFormReducer from '../reducers/bookingsForm';
import analyticsReducer from '../reducers/analytics';
import reportFormReducer from '../reducers/reportForm';
import reportsReducer from '../reducers/reports';
import summaryReportsReducer from '../reducers/summaryReports';
import vendorFormReducer from '../reducers/vendorForm';
import vendorsReducer from '../reducers/vendors';
import associationFormReducer from '../reducers/associationForm';
import associationsReducer from '../reducers/associations';
import profileFormReducer from '../reducers/profileForm';

export default () => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            loginForm: loginFormReducer,
            sidebar: sidebarReducer,
            modals: modalsReducer,
            users: usersReducer,
            userForm: userFormReducer,
            occupancyForm: occupancyFormReducer,
            dashboard: dashboardReducer,
            selectedAssociation: selectedAssociationReducer,
            bookings: bookingsReducer,
            analytics: analyticsReducer,
            bookingsForm: bookingsFormReducer,
            reportForm: reportFormReducer,
            reports: reportsReducer,
            summaryReports: summaryReportsReducer,
            vendorForm: vendorFormReducer,
            vendors: vendorsReducer,
            associationForm: associationFormReducer,
            associations: associationsReducer,
            profileForm: profileFormReducer
        }),
        {},
        applyMiddleware(ReduxThunk)
    );

    return store;
}