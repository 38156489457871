export const bookingsFormUpdate = ({ prop, value }) => {

    return {
        type: 'BOOKINGS_FORM_UPDATE',
        field: { prop, value }
    };
};

export const bookingsFormDayUpdate = (day, value) => {
    return {
        type: 'BOOKINGS_FORM_DAY_UPDATE',
        day,
        value
    };
};

export const bookingsFormDayOccupantUpdate = (day, value) => {
    return {
        type: 'BOOKINGS_FORM_DAY_OCCUPANT_UPDATE',
        day,
        value
    };
};

export const bookingsFormError = (message) => {

    return {
        type: 'BOOKINGS_FORM_ERROR',
        message
    };
};


export const bookingsFormSuccess = () => {
    return {
        type: 'BOOKINGS_FORM_SUCCESS'
    };
};

export const bookingsFormReset = () => {
    
    return {
        type: 'BOOKINGS_FORM_RESET',
    };
}