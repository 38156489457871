import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { reportFormSuccess, reportFormError } from '../actions/reportForm';
import { closeReportsCreate } from './modals';
import { setDashboardVendor } from './dashboard';
import generateSubmittedReportPdf from '../utils/reports/generateSubmittedReportPdf';

export const setReports = (reports) => ({
    type: 'SET_REPORTS',
    reports
});

export const setReportsLoading = () => ({
    type: 'SET_REPORTS_LOADING'
});

export const getReports = (data) => {

    return (dispatch) => {

        dispatch(setReportsLoading());
        return axios.get(API_URL+'/api/reports', {
            params: data
        })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setReports(res.data.reports));
                    dispatch(setDashboardVendor(res.data.vendor));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get reports!");
                    alert("Could not get reports!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the report to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updatePaymentStatus = (rid, { payment_complete } = {}) => {
    
    return (dispatch) => {

        dispatch(setReportsLoading());
        return axios.post(API_URL+'/api/reports/'+rid+'/updatePayment', { paymentComplete: payment_complete })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setReports(res.data.reports));
                    dispatch(reportFormSuccess('Payment Status Updated!'));
                }
                else {
                    dispatch(reportFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your payment status");
                }
            })
            .catch(function(err) {
                // token is likely invalid; remove it and prompt the report to login again
                dispatch(reportFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const generateSubmittedReport = (report) => {
    console.log("generating submitted report");
    console.log(report);
    generateSubmittedReportPdf(report);
}

// export const addReport = (id, { email, role } = {}) => {
    
//     return (dispatch) => {

//         dispatch(setReportsLoading());
//         return axios.post(API_URL+'/api/reports/'+id+'/create', { email, role })
//             .then(function(res) {
//                 if (res.data.status == 'success') {
//                     dispatch(setReports(res.data.reports));
//                     dispatch(reportFormReset());
//                     dispatch(closeReportsCreate());
//                 }
//                 else {
//                     dispatch(reportFormError(res.data.message));
//                 }
//             })
//             .catch(function(err) {
                
//                 // token is likely invalid; remove it and prompt the report to login again
//                 dispatch(reportFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
//                 if (err.response.status === 401) {
//                     removeAuthToken(dispatch);
//                 }
                
//             });
//     };
// };

// export const updateReport = (id, mid, { role } = {}) => {
    
//     return (dispatch) => {

//         dispatch(setReportsLoading());
//         return axios.put(API_URL+'/api/reports/'+id+'/'+mid, { role })
//             .then(function(res) {
//                 if (res.data.status == 'success') {
//                     dispatch(setReports(res.data.reports));
//                     dispatch(reportFormSuccess('Report Role Updated!'));
//                 }
//                 else {
//                     dispatch(reportFormError(res.data.message));
//                     console.log(res.data);
//                     console.log("Could not add your report!");
//                 }
//             })
//             .catch(function(err) {

//                 // token is likely invalid; remove it and prompt the report to login again
//                 dispatch(reportFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
//                 if (err.response.status === 401) {
//                     removeAuthToken(dispatch);
//                 }
                
//             });
//     };
// };

// export const removeReport = (mid, id) => {

//     return (dispatch) => {

//         dispatch(setReportsLoading());
//         return axios.delete(API_URL+'/api/reports/'+id+'/'+mid, {})
//             .then(function(res) {
//                 if (res.data.status == 'success') {
//                     dispatch(setReports(res.data.reports));
//                 }
//                 else {
//                     // dispatch(reportFormError(res.data.message));
//                     console.log(res.data);
//                     console.log("Could not remove your report!");
//                 }
//             })
//             .catch(function(err) {

//                 // token is likely invalid; remove it and prompt the report to login again
//                 if (err.response.status === 401) {
//                     removeAuthToken(dispatch);
//                 }
                
//             });
//     };
// }