import React from 'react';
import moment from 'moment';
import { render } from 'react-dom';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import verifyLogin from '../utils/verifyLogin';
import { getMonthName, getCurrentTime } from '../utils/dateUtil';
import setBackgroundPhoto from '../utils/setBackgroundPhoto';
import { setSelectedAssociationID } from '../actions/selectedAssociation';
import { getDailyBookingsInfo, submitDailyBookings } from '../actions/bookings';
import { openReportModal, closeReportModal } from '../actions/modals';
import { reportFormUpdate, reportFormPurposeUpdate, updateReportData, reportFormError } from '../actions/reportForm';
import { bookingsFormDayUpdate, bookingsFormDayOccupantUpdate, bookingsFormError } from '../actions/bookingsForm';
import VendorPDF from './pdf/VendorAccommodations';

import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import RoomBookingsInformation from './RoomBookingsInformation';

class DailyBookingsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleReportModal = this.toggleReportModal.bind(this);
        this.submitDailyBookingsUpdate = this.submitDailyBookingsUpdate.bind(this);
        this.viewDailyBookingsPrev = this.viewDailyBookingsPrev.bind(this);
        this.viewDailyBookingsNext = this.viewDailyBookingsNext.bind(this);
        this.generateBookingsReport = this.generateBookingsReport.bind(this);
        this.buildBookingsPDF = this.buildBookingsPDF.bind(this);
        this.generatePDF = this.generatePDF.bind(this);
        this.createAndDownloadPDF = this.createAndDownloadPDF.bind(this);
        this.submitGenerateBookingsReport = this.submitGenerateBookingsReport.bind(this);

        this.state = {
            loading: false,
            initLoad: false,
            sidebarMenuOpen: false
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
        verifyLogin(this.props.history);

        let theMonth = null,
            theYear = null;
        if (this.props.match.params.month && this.props.match.params.year) {
            theMonth = this.props.match.params.month;
            theYear = this.props.match.params.year;
        }

        this.props.dispatch(getDailyBookingsInfo(this.props.match.params.id, this.props.match.params.vid, theMonth, theYear));
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
        if (this.props.match.params.month && this.props.match.params.year) {
            
            if (!this.props.loading && (parseInt(this.props.match.params.month) != (this.props.bookings_date.getMonth() + 1) || this.props.bookings_date.getFullYear() != this.props.match.params.year)) {

                let theMonth = null,
                    theYear = null;
                if (this.props.match.params.month && this.props.match.params.year) {
                    theMonth = this.props.match.params.month;
                    theYear = this.props.match.params.year;
                }

                this.props.dispatch(getDailyBookingsInfo(this.props.match.params.id, this.props.match.params.vid, theMonth, theYear));
            }
        }
    }

    viewDailyBookingsPrev() {
        let momentDate = moment(this.props.bookings_date).subtract(1, 'months');
        this.props.history.push('/'+this.props.match.params.id+'/bookings/'+this.props.match.params.vid+'/'+momentDate.format('MM')+'/'+momentDate.format('YYYY'));
    }

    viewDailyBookingsNext() {
        let momentDate = moment(this.props.bookings_date).add(1, 'months');
        this.props.history.push('/'+this.props.match.params.id+'/bookings/'+this.props.match.params.vid+'/'+momentDate.format('MM')+'/'+momentDate.format('YYYY'));
    }

    generateBookingsReport() {

        if (this.checkAllDaysEntered()) {

            // @TODO: eventually set up the generation of this report
            // @TODO: we will also want to submit the data to ensure it's all there

            alert("Coming Soon!");
        }
        else {
            this.props.dispatch(bookingsFormError("Please enter a booking total for every day in the month."));
        }
    }

    submitDailyBookingsUpdate() {

        // submit the time to book
        this.props.dispatch(submitDailyBookings(this.props.sitename, this.props.vendor.id, this.props.bookings, this.props.occupants, this.props.bookings_date));
    }

    checkAllDaysEntered() {
        const total_days = new Date(this.props.bookings_date.getFullYear(), this.props.bookings_date.getMonth()+1, 0).getDate();
        let isValid = true;

        for (let i = 1; i <= total_days; i++) {
            if (this.props.bookings[i] === '' || this.props.bookings[i] === null || this.props.bookings[i] === undefined || this.props.bookings[i] === false || this.props.occupants[i] === '' || this.props.occupants[i] === null || this.props.occupants[i] === undefined || this.props.occupants[i] === false) {
                isValid = false;
            }
        }

        return isValid;
    }

    renderDailyBookingsForm() {
        const total_days = new Date(this.props.bookings_date.getFullYear(), this.props.bookings_date.getMonth()+1, 0).getDate();
        let dailyBookingsItems = [];

        for (let i = 1; i <= total_days; i++) {
            const dailyBookingsItem = (
                <div className="daily-bookings-form-item" key={"bookings-"+i}>
                    <p className="daily-bookings-form-date">{getMonthName(this.props.bookings_date.getMonth())} {i}, {this.props.bookings_date.getFullYear()}</p>
                    
                    <TextField
                        className="daily-bookings-input daily-bookings-input-occupants"
                        label="Occupants"
                        fullWidth={false}
                        value={this.props.occupants[i] || ''}
                        onChange={(e) => { this.props.dispatch(bookingsFormDayOccupantUpdate(i, e.target.value)); }}
                        margin="dense"
                        variant="outlined"
                    />
                    
                    <TextField
                        className="daily-bookings-input"
                        label="Rooms Booked"
                        fullWidth={false}
                        value={this.props.bookings[i] || ''}
                        onChange={(e) => { this.props.dispatch(bookingsFormDayUpdate(i, e.target.value)); }}
                        margin="dense"
                        variant="outlined"
                    />
                    
                </div>
            );
            dailyBookingsItems.push(dailyBookingsItem);
        }

        return dailyBookingsItems;
    }

    monthlyBookingsChangeStyle(val) {
        if (val > 0) {
            return "monthly-bookings-change monthly-bookings-change-plus";
        }
        else if (val < 0) {
            return "monthly-bookings-change monthly-bookings-change-minus";
        }
        else {
            return "monthly-bookings-change";
        }
    }

    printmonthlyBookingsChange(val) {
        if (val > 0) {
            return "+"+val+"%";
        }
        else if (val < 0) {
            return val+"%";
        }
        else {
            return val;
        }
    }

    buildBookingsPDF() {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.createAndDownloadPDF(
                    this.generatePDF(),
                    'tourism_ns_form.pdf',
                    'tourism-ns-form',
                    () => this.setState({ loading: false })
                );
            });
        }
    }

    createAndDownloadPDF(pdfContent, filename, divId, callback) {
        setTimeout(() => {
            const link = (
                <div id={divId}>
                    <PDFDownloadLink document={pdfContent} fileName={filename}>
                        {({ loading }) => {
                            if (!loading) {
                                setTimeout(() => {
                                    document.getElementById(divId).children[0].click();
                                    callback();
                                }, 3);
                            }
                        }}
                    </PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            elem.setAttribute("id", 'vendor-pdf-window');

            // remove 
            var elemToRemove = document.getElementById("vendor-pdf-window");

            if (elemToRemove) {
                elemToRemove.parentNode.removeChild(elemToRemove);
            }

            document.getElementById('app').appendChild(elem);
            render(link, elem);
        }, 50);
    }

    generatePDF() {
        // VendorPDF is a component that returns a PDF <Document />
        return <VendorPDF bookings_date={this.props.bookings_date} vendor={this.props.vendor} bookings={this.props.bookings} occupants={this.props.occupants} reportData={this.props.reportForm} total_occupancy={this.props.total_occupancy} />;
    }

    toggleReportModal(isOpen) {

        if (isOpen) {
            this.props.dispatch(openReportModal());
        }
        else {
            this.props.dispatch(closeReportModal());
        }
    }

    submitGenerateBookingsReport() {

        // ensure that the purpose totals are correct
        const purpose_total = parseInt(this.props.reportForm.trip_purpose.vacationing) + 
            parseInt(this.props.reportForm.trip_purpose.business) + 
            parseInt(this.props.reportForm.trip_purpose.convention) + 
            parseInt(this.props.reportForm.trip_purpose.motorcoach) + 
            parseInt(this.props.reportForm.trip_purpose.other);

        if (purpose_total != 100) {

            // required fields aren't present
            this.props.dispatch(reportFormError("Please ensure that all of your trip purpose percentages equal 100."));
        }
        else {

            // submit the report data and generate the PDF
            this.props.dispatch(updateReportData(this.props.match.params.id, this.props.vendor.id, getCurrentTime(true), this.props.reportForm));
            this.buildBookingsPDF();
        }
    }

    renderBookingsReportModal() {
        return (
            <Modal
                aria-labelledby="render-bookings-pdf"
                aria-describedby="render-bookings-pdf"
                open={this.props.reportModalOpen}
                onClose={() => this.toggleReportModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Generate Bookings Report
                    </Typography>
                    <Typography className="modal-description">
                        Almost there! We just need you to verify the following:
                    </Typography>

                    { this.props.reportForm.error && (
                        <Typography className="modal-error">
                            { this.props.reportForm.error }
                        </Typography>
                    )}

                    <GridContainer className="trip-purpose-grid">
                        <GridItem xs={12} sm={12} md={12}>
                            <Typography variant="h5" className="modal-subtitle">
                                Trip Purpose Information
                            </Typography>
                            <Typography className="modal-description">
                                Please review and enter your trip purpose totals in percentage. PLEASE NOTE: All trip purpose numbers must add up to 100%.
                            </Typography>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1}></GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <TextField
                                id="purpose-vacationing"
                                label="Vacationing"
                                className="modal-input"
                                fullWidth={true}
                                value={this.props.reportForm.trip_purpose.vacationing}
                                onChange={(e) => { this.props.dispatch(reportFormPurposeUpdate({ prop: 'vacationing', value: e.target.value })); }}
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <TextField
                                id="purpose-business"
                                label="Business"
                                className="modal-input"
                                fullWidth={true}
                                value={this.props.reportForm.trip_purpose.business}
                                onChange={(e) => { this.props.dispatch(reportFormPurposeUpdate({ prop: 'business', value: e.target.value })); }}
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <TextField
                                id="purpose-convention"
                                label="Convention"
                                className="modal-input"
                                fullWidth={true}
                                value={this.props.reportForm.trip_purpose.convention}
                                onChange={(e) => { this.props.dispatch(reportFormPurposeUpdate({ prop: 'convention', value: e.target.value })); }}
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <TextField
                                id="purpose-motorcoach"
                                label="Motorcoach"
                                className="modal-input"
                                fullWidth={true}
                                value={this.props.reportForm.trip_purpose.motorcoach}
                                onChange={(e) => { this.props.dispatch(reportFormPurposeUpdate({ prop: 'motorcoach', value: e.target.value })); }}
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <TextField
                                id="purpose-other"
                                label="Other"
                                className="modal-input"
                                fullWidth={true}
                                value={this.props.reportForm.trip_purpose.other}
                                onChange={(e) => { this.props.dispatch(reportFormPurposeUpdate({ prop: 'other', value: e.target.value })); }}
                                margin="normal"
                            />
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1}></GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Typography variant="h5" className="modal-subtitle">
                                Closed for the Season
                            </Typography>
                            <Typography className="modal-description">
                                Are you currently closed for the season?
                            </Typography>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1}></GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <FormControlLabel
                                control={<Checkbox value="true" checked={this.props.reportForm.closed_for_season} onChange={(e) => this.props.dispatch(reportFormUpdate({ prop: 'closed_for_season', value: !this.props.reportForm.closed_for_season }))} color="primary" />}
                                label="We're closed for the season."
                            />
                        </GridItem>
                    </GridContainer>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.submitGenerateBookingsReport}>
                        Generate Bookings Report
                    </Button>
                </div>
            </Modal>
        )
    }
    
    render() {
        setBackgroundPhoto('');
        const data = {
            labels: ['2014', '2015', '2016', '2017', '2018'],
            datasets: [
              {
                label: 'Occupancy Rate',
                backgroundColor: '#38b6ff',
                borderColor: '#38b6ff',
                borderWidth: 1,
                hoverBackgroundColor: '#134866',
                hoverBorderColor: '#134866',
                data: this.props.occupancy_data
              }
            ]
        };

        return (
            <GridContainer>
                {this.renderBookingsReportModal()}
                <GridItem xs={12} sm={12} md={12}>
                    <div className="daily-bookings-header-window">
                        <h1 className="daily-bookings-title">Daily Bookings - {this.props.vendor.name}</h1>
                        <Button
                            type="button"
                            size="large"
                            variant="contained"
                            className="submit-daily-bookings-button"
                            onClick={this.submitDailyBookingsUpdate}
                        >
                            Save Changes
                        </Button>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <div className="daily-bookings-window">
                        <h2>{getMonthName(this.props.bookings_date.getMonth())} {this.props.bookings_date.getFullYear()} - Avg. Daily Capacity: {this.props.average_daily_capacity.toFixed(2)}</h2>

                        <div className="month-toggle-window">
                            <Button
                                type="button"
                                // variant="contained"
                                className="daily-bookings-prev"
                                onClick={this.viewDailyBookingsPrev}
                            >
                                Prev Month
                            </Button>

                            <Button
                                type="button"
                                // variant="contained"
                                className="daily-bookings-next"
                                onClick={this.viewDailyBookingsNext}
                            >
                                Next Month
                            </Button>
                        </div>

                        { this.props.error &&  <p className="bookings-error-text">{this.props.error}</p> }
                        { this.props.success &&  <p className="bookings-success-text">Bookings Updated!</p> }
                    </div>
                    {this.renderDailyBookingsForm()}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <div className="daily-bookings-totals-window">
                        <h2>Total Monthly Bookings</h2>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <p className="monthly-bookings-title">Total</p>
                                <p className="monthly-bookings-number">{this.props.monthly_totals.total.total}</p>
                                <p className={this.monthlyBookingsChangeStyle(this.props.monthly_totals.total.change)}>{this.printmonthlyBookingsChange(this.props.monthly_totals.total.change)}</p>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <p className="monthly-bookings-title">Occupancy Rate</p>
                                <p className="monthly-bookings-number">{this.props.monthly_totals.occupancy_rate.total}%</p>
                                <p className={this.monthlyBookingsChangeStyle(this.props.monthly_totals.occupancy_rate.change)}>{this.printmonthlyBookingsChange(this.props.monthly_totals.occupancy_rate.change)}</p>
                            </GridItem>
                        </GridContainer>
                    </div>
                    { (this.checkAllDaysEntered()) ? (
                        <Button
                            type="button"
                            size="large"
                            fullWidth={true}
                            variant="contained"
                            className="button-generate-bookings-report"
                            // onClick={this.buildBookingsPDF}
                            onClick={() => this.toggleReportModal(true)}
                        >
                            Generate Bookings Report
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            size="large"
                            fullWidth={true}
                            variant="contained"
                            className="button-generate-bookings-report"
                            onClick={this.generateBookingsReport}
                        >
                            Generate Bookings Report
                        </Button>
                    )}
                    
                    <div className="annual-comparison-window">
                        <h2>Annual Comparison</h2>
                        <Bar
                            data={data}
                            height={200}
                            options={{
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            max: 100,
                                            min: 0,
                                            stepSize: 20
                                        }
                                    }]
                                },
                            }}
                        />

                    </div>
                </GridItem>
            </GridContainer>
        )
    }
};

const mapStateToProps = (state, props) => {
    return {
        loggedUser: state.auth.user,
        loggedRole: state.auth.role,
        reportModalOpen: state.modals.reportModal,
        association_id: state.selectedAssociation.id,
        isAuthenticated: state.auth.isAuthenticated,
        sitename: state.selectedAssociation.id,
        vendors: state.dashboard.vendors,
        total_occupancy: state.bookings.total_occupancy,
        vendor: state.bookings.vendor,
        occupancy_data: state.bookings.occupancy_data,
        bookings_date: new Date(state.bookings.bookings_date),
        average_daily_capacity: state.bookings.average_daily_capacity,
        bookings: state.bookingsForm.bookings,
        occupants: state.bookingsForm.occupants,
        monthly_totals: state.bookings.monthly_totals,
        reportForm: state.reportForm,
        loading: state.bookings.loading,
        error: state.bookingsForm.error,
        success: state.bookingsForm.success,
    };
};

export default connect(mapStateToProps)(DailyBookingsPage);