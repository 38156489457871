// Get visible reports

export default (reports, { search = '', sort_value='vendor.name', sort_direction='asc'}) => {

    return reports.filter((report) => {
        let textNameMatch = false;

        textNameMatch = report.vendor.name.toLowerCase().includes(search.toLowerCase()) ||  report.vendor.address.toLowerCase().includes(search.toLowerCase());
        return textNameMatch;

    }).sort((a, b) => {

        let sortValues = sort_value.split('.');
        if (sort_direction === 'desc') {

            if (sortValues.length == 2) {
                return a[sortValues[0]][sortValues[1]] <= b[sortValues[0]][sortValues[1]] ? 1 : -1;
            }

            if (sort_value === 'date') {
                let aDate = new Date(a.raw_date);
                let bDate = new Date(b.raw_date);
                return aDate <= bDate ? 1 : -1;
            }
            
            if (sort_value === 'total_due') {
                return parseFloat(a[sort_value]) <= parseFloat(b[sort_value]) ? 1 : -1;
            }

            return a[sort_value] <= b[sort_value] ? 1 : -1;
        }

        if (sortValues.length == 2) {
            return a[sortValues[0]][sortValues[1]] > b[sortValues[0]][sortValues[1]] ? 1 : -1;
        }

        if (sort_value === 'date') {
            let aDate = new Date(a.raw_date);
            let bDate = new Date(b.raw_date);
            return aDate > bDate ? 1 : -1;
        }

        if (sort_value === 'total_due') {
            return parseFloat(a[sort_value]) > parseFloat(b[sort_value]) ? 1 : -1;
        }

        
        return a[sort_value] > b[sort_value] ? 1 : -1;
    });
}; 