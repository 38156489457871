export const openProfileUpdate = () => {

    return {
        type: 'OPEN_PROFILE_UPDATE_MODAL',
    };
};

export const closeProfileUpdate = () => {

    return {
        type: 'CLOSE_PROFILE_UPDATE_MODAL',
    };
};

export const openUsersCreate = () => {

    return {
        type: 'OPEN_USERS_CREATE_MODAL',
    };
};

export const closeUsersCreate = () => {

    return {
        type: 'CLOSE_USERS_CREATE_MODAL',
    };
};

export const openUsersUpdate = () => {

    return {
        type: 'OPEN_USERS_UPDATE_MODAL',
    };
};

export const closeUsersUpdate = () => {

    return {
        type: 'CLOSE_USERS_UPDATE_MODAL',
    };
};

export const openReportModal = () => {

    return {
        type: 'OPEN_REPORT_MODAL',
    };
};

export const closeReportModal = () => {

    return {
        type: 'CLOSE_REPORT_MODAL',
    };
};

export const openSummaryReportModal = () => {
    return {
        type: 'OPEN_SUMMARY_REPORT_MODAL',
    };
};

export const closeSummaryReportModal = () => {

    return {
        type: 'CLOSE_SUMMARY_REPORT_MODAL',
    };
};

export const openVendorsCreate = () => {

    return {
        type: 'OPEN_VENDORS_CREATE_MODAL',
    };
};

export const closeVendorsCreate = () => {

    return {
        type: 'CLOSE_VENDORS_CREATE_MODAL',
    };
};

export const openVendorsUpdate = () => {

    return {
        type: 'OPEN_VENDORS_UPDATE_MODAL',
    };
};

export const closeVendorsUpdate = () => {

    return {
        type: 'CLOSE_VENDORS_UPDATE_MODAL',
    };
};

export const openMunicipalitiesCreate = () => {

    return {
        type: 'OPEN_MUNICIPALITIES_CREATE_MODAL',
    };
};

export const closeMunicipalitiesCreate = () => {

    return {
        type: 'CLOSE_MUNICIPALITIES_CREATE_MODAL',
    };
};

export const openMunicipalitiesUpdate = () => {

    return {
        type: 'OPEN_MUNICIPALITIES_UPDATE_MODAL',
    };
};

export const closeMunicipalitiesUpdate = () => {

    return {
        type: 'CLOSE_MUNICIPALITIES_UPDATE_MODAL',
    };
};

export const openAssociationsCreate = () => {

    return {
        type: 'OPEN_ASSOCIATIONS_CREATE_MODAL',
    };
};

export const closeAssociationsCreate = () => {

    return {
        type: 'CLOSE_ASSOCIATIONS_CREATE_MODAL',
    };
};

export const openAssociationsUpdate = () => {

    return {
        type: 'OPEN_ASSOCIATIONS_UPDATE_MODAL',
    };
};

export const closeAssociationsUpdate = () => {

    return {
        type: 'CLOSE_ASSOCIATIONS_UPDATE_MODAL',
    };
};

export const resetModals = () => {

    return {
        type: 'RESET_MODALS',
    };
};