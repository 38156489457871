import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import CreateVendor from "./modal/CreateVendor";
import UpdateVendor from "./modal/UpdateVendor";
import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import { getVendors, generateVendorReport, removeVendor } from '../actions/vendors';
import { vendorFormUpdate, vendorFormError, vendorFormReset, vendorFormLoad } from '../actions/vendorForm';
import { openVendorsCreate, closeVendorsCreate, openVendorsUpdate, closeVendorsUpdate } from '../actions/modals';
import { setSelectedAssociationID } from '../actions/selectedAssociation';
import filterVendors from '../selectors/vendors';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class AccommodationsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.removeVendor = this.removeVendor.bind(this);

        this.state = {
            selectedVendor: {}
        };
    }

    generateVendorReport(report) {
        this.props.dispatch(generateVendorReport(report));
    }

    removeVendor({ id }) {
        if (confirm("Are you sure you want to delete this vendor? This cannot be undone")) {
            this.props.dispatch(removeVendor(id));
        };
    }

    componentDidMount() {
        verifyLogin(this.props.history);

        this.props.dispatch(getVendors());
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            console.log(this.props.loggedRole);
            this.props.dispatch(vendorFormReset());
            this.props.dispatch(vendorFormUpdate({ prop: 'municipality_id', value: this.props.loggedRole.municipality_id }));  // set the municipality ID
            this.props.dispatch(openVendorsCreate());
        }
        else {
            this.props.dispatch(closeVendorsCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedVendor) {
        if (isOpen) {
            this.setState(() => ({
                selectedVendor
            }));
            this.props.dispatch(vendorFormReset());
            this.props.dispatch(vendorFormLoad(selectedVendor));
            this.props.dispatch(openVendorsUpdate());
        }
        else {
            this.props.dispatch(closeVendorsUpdate());
        }
    }

    renderUpdateModal() {
        return <UpdateVendor selectedVendor={this.state.selectedVendor} />;
    }

    renderCreateModal() {
        return <CreateVendor />;
    }

    printRole(theRole) {
        switch(theRole) {

            case "owner":
                return "Association Owner";

            case "admin":
                return "Administrator";

            case "manager_schedule":
                return "Schedule Manager";

            case "manager_all":
                return "Schedule/Team Manager";

            case "manager_team":
                return "Team Manager";
            
            case "coach":
                return "Coach";

            default: 
                return theRole;
        }
    }

    printStatus(theStatus) {
        if (theStatus == "confirmed") {
            return "Confirmed";
        }
        else if (theStatus == "pending") {
            return "Pending";
        }
        else {
            return theStatus;
        }
    }

    renderVendorsList() {
        const { classes } = this.props;

        return (
            
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <h4 className={classes.cardTitleWhite}>{this.props.association.name}</h4>
                            <p className={classes.cardCategoryWhite}>
                            Vendors
                            </p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="text"
                                        value={this.props.vendorForm.search}
                                        onChange={(e) => { this.props.dispatch(vendorFormUpdate({ prop: 'search', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                            <Table
                                tableHead={["Name", "Licence Number", "Vendor Type", "Owner Name", "Owner Email", ""]}
                                tableData={filterVendors(this.props.vendors, {search: this.props.vendorForm.search}).map((vendor) => [
                                    vendor.name, 
                                    vendor.licence_number, 
                                    vendor.vendor_type,
                                    vendor.owner_name,
                                    vendor.owner_email,
                                    <div>
                                        <Icon className="fa fa-file-invoice manager-table-icon manager-table-edit" onClick={() => this.generateVendorReport(vendor)} />
                                        <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, vendor)} />
                                        <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeVendor(vendor)} />
                                    </div>
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderVendorsList()}

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        loggedRole: state.auth.role,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.vendors.loading,
        vendors: state.vendors.vendors,
        vendorForm: state.vendorForm,
        createModalOpen: state.modals.vendorsCreate,
        updateModalOpen: state.modals.vendorsUpdate,
        association: state.selectedAssociation.association,
        associationsList: state.vendors.associations_list,
        municipalitiesList: state.vendors.municipalities_list,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(AccommodationsPage));
