import React from 'react';
import { Link } from 'react-router-dom';

const changeDisplayCSS = (val) => {

    if (val > 0) {
        return "room-bookings-change-positive";
    }
    else if (val < 0) {
        return "room-bookings-change-negative";
    }
    else {
        return "room-bookings-change-neutral";
    }
};


const printChange = (val) => {

    if (val != "N/A") {
        return val+"%";
    }
    else {
        return val;
    }
};

const RoomBookingsInformation = ({title, total, change}) => {
    return (
        <div className="room-bookings-window">
            <h3>{title}</h3>
            <p className="room-bookings-total">{total}</p>
            <p className={changeDisplayCSS(change)}>{printChange(change)}</p>
        </div>
    );
}

export default RoomBookingsInformation;