export const userFormUpdate = ({ prop, value }) => {

    return {
        type: 'USER_FORM_UPDATE',
        field: { prop, value }
    };
};

export const userFormError = (message) => {

    return {
        type: 'USER_FORM_ERROR',
        message
    };
};

export const userFormSuccess = (message) => {

    return {
        type: 'USER_FORM_SUCCESS',
        message
    };
};

export const userFormReset = () => {
    
    return {
        type: 'USER_FORM_RESET',
    };
}